import React from "react";
import Barner from "../Components/Home/Barner";

import Map1 from "../Components/map/Map1";

const About = () => {
  return (
    <div style={{ zIndex: -99999 }} className=" flex flex-col gap-12 pb-10">
      <Barner />
      <div className="flex flex-col gap-[35px] max-w-[800px] m-auto">
        <span className="text-[#fff] text-[32px] text-center">
          Company Overview
        </span>
        <div className="flex flex-col items-center gap-[20px]">
          <span className="text-center leading-[30px] m-auto text-[#ffffff70]">
            We provide goods in several categories, including speakers, mixers
            and amplifiers, microphones, musical instruments, home automation
            appliances, and much more to a retail client base that is always
            expanding tremendously.
          </span>
          <span className="text-center leading-[30px] m-auto text-[#ffffff70]">
            Our suite of services is tailored to assure the highest levels of
            customer happiness and convenience throughout the retail process;
            these services include order delivery tracking, our lowest price
            guarantee, and many other premium offerings.
          </span>
          <span className="text-center leading-[30px] m-auto text-[#ffffff70]">
            Our range of offerings will grow in variety, ease of use, and
            convenience as we continue to expand; come along and take advantage
            of the growing advantages.
          </span>
        </div>
      </div>
      <div className="flex flex-col gap-[30px] max-w-[800px] m-auto w-full py-[40px]">
        <span className="text-[#fff] text-[32px]">Culture & Values</span>
        <div className="flex flex-col gap-[20px]">
          <span className="text-[24px] text-[#ffffffa9] border-b w-fit">
            Our Mission
          </span>
          <span className=" leading-[30px] text-[#ffffff70]">
            To connect Africans with each other and the rest of the world
            through Technology & Commerce.
          </span>
        </div>
        <div className="flex flex-col gap-[20px]">
          <span className="text-[24px] text-[#ffffffa9] border-b w-fit">
            Our Values
          </span>
          <span className=" leading-[30px] text-[#ffffff70]">
            <b className="text-[#ffffffc0]">Honour & Integrity :</b> We are
            open, honest and trustworthy in all our dealings.
          </span>
          <span className=" leading-[30px] text-[#ffffff70]">
            <b className="text-[#ffffffc0]">Hardwork :</b> We value diligence.
            We are aware that the only way for us to improve as people and as a
            business is by pushing ourselves past our comfort zones.
          </span>
          <span className=" leading-[30px] text-[#ffffff70]">
            <b className="text-[#ffffffc0]">Constant Evolution :</b> We always
            challenge convention in search of chances to generate original
            ideas. As people, we welcome quick changes and adaptability.
          </span>
          <span className=" leading-[30px] text-[#ffffff70]">
            <b className="text-[#ffffffc0]"> Teamwork & Sacrifice :</b> In order
            to meet the needs of our clients and develop a better business, we
            collaborate without regard for personal interests.
          </span>
        </div>
      </div>
      <div className="flex flex-col gap-[30px]  m-auto w-full py-[40px]">
        <Map1 />
      </div>
    </div>
  );
};

export default About;

import React, { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import OrderCard from "../Components/Order/OrderCard";
import useOrderService from "../MiddleWares/services/orderService";
import { BiArrowBack } from "react-icons/bi";
import Order from "./Order";
import { useAppContext } from "../MiddleWares/context/appContext";

const Orders = () => {
  const route = useParams();
  const { fetchOrders } = useOrderService();
  const { order, setOrder } = useAppContext();
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(4);
  const navigate = useNavigate();

  const getOrders = async () => {
    // setLoading(true);
    const res = await fetchOrders();
    // console.log(res);
    if (res.message === "success") {
      setOrders(res.data);
    }
  };

  useEffect(() => {
    if (!localStorage.esp_token) navigate("/login");
    getOrders();
  }, []);

  return (
    <div className="grid grid-cols-12 gap-5 min-h-[600px] max-w-[1200px] m-auto">
      <div
        className={` ${
          order ? "md:col-span-7 col-span-12" : "col-span-12"
        } h-full py-[30px] flex flex-col gap-[30px] `}
      >
        <Link
          to={"/orders"}
          className={`md:text-[36px] text-[32px] text-[#fff]`}
        >
          All Orders
        </Link>

        <div
          className={`w-full gap-[40px] grid-cols-12 md:grid flex flex-wrap `}
        >
          {[...orders]
            .reverse()
            .slice(0, page)
            .map((order, n) => {
              return <OrderCard data={order} key={n} />;
            })}
          <div className="col-span-12">
            {orders.length > 4 && (
              <span
                onClick={() =>
                  page === 4 ? setPage(orders.length) : setPage(4)
                }
                className="text-[--red] underline text-end"
              >
                {page === 4 ? " See all..." : "See less..."}
              </span>
            )}
          </div>
        </div>
        {order && (
          <div
            className={
              order
                ? "md:hidden sm_shadow  flex items-center justify-center fixed top-0 left bg-[#ffffff75] left-0 right-0 h-screen w-screen p-5"
                : ""
            }
          >
            <div className="bg-[#000] p-5 ">
              <div
                onClick={() => setOrder(null)}
                className=" mb-[25px] text-[red] flex items-center gap-5"
              >
                <AiOutlineClose className="text-[red]" />
                Close
              </div>
              <Order />
            </div>
          </div>
        )}
      </div>
      {order && (
        <div className="  col-span-5 h-full py-[30px] border-l-2 px-5 md:flex hidden flex-col gap-[30px]">
          <span className="md:text-[36px] text-[32px] text-[#fff]">
            Current Order
          </span>
          <Order />
        </div>
      )}
    </div>
  );
};

export default Orders;

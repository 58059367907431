import { toast } from "react-hot-toast";
import { useAppContext } from "../context/appContext";

export class productSevice {
   baseURL = `https://esp-api.azurewebsites.net`;
  //  baseURL = `https://vast-puce-cricket-vest.cyclic.app`;
  //  baseURL = `http://localhost:2000`;
  state = useAppContext();

  fetchProducts = async () => {
    try {
      const res = await fetch(this.baseURL + `/product/get-all-products`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const response = await res.json();
      this.state.setProducts(response.products);
      const arrivals = response.products.filter(
        (product) => product.category.category_id === "6479c8852f2c29671d3141b3"
      );

      this.state.setNewArrivals(arrivals);
      return { message: "success", data: response.products };
    } catch (err) {
      return { message: "failed" };
    }
  };
  fetchCategories = async () => {
    try {
      const res = await fetch(this.baseURL + `/product/categories`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const response = await res.json();
      this.state?.setCategories(response.data);
      console.log(response);
      return { message: "success", data: response.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
  getProduct = async (id) => {
    try {
      const res = await fetch(this.baseURL + `/product/get-product/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const response = await res.json();
      console.log(response);
      return { message: "success", data: response.products };
    } catch (err) {
      console.log("err", err);
      toast.error("Failed to fetch product");
      return { message: "failed" };
    }
  };
}

import { useAppContext } from "../MiddleWares/context/appContext";
import { cartSevice } from "../MiddleWares/services/cartService";
import { productSevice } from "../MiddleWares/services/productService";

export const useData = () => {
  const productService = new productSevice();
  const cartService = new cartSevice();
  const appContext = useAppContext();

  const fetchProducts = async () => {
    await productService.fetchCategories();
    const res = await productService.fetchProducts();
    if (res.message === "success") {
      if (appContext?.targetCategory === "All products")
        appContext?.setFilteredProducts(res.data);
      else
        appContext?.setFilteredProducts(
          res.data.filter(
            (product) =>
              product.category.category_name === appContext?.targetCategory
          )
        );
    }
  };
  const filterProducts = (category) => {
    appContext?.setTargetCategory(category);
    const products = appContext?.products;
    if (category === "All Products") appContext?.setFilteredProducts(products);
    else
      appContext?.setFilteredProducts(
        products?.filter(
          (product) => product.category.category_name === category
        )
      );
  };
  const fetchCart = async () => {
    if (localStorage.esp_token) {
      await cartService.fetchCart();
    } else {
      // console.log("test");
      cartService.fetchLocalCart();
    }
  };
  return { fetchProducts, fetchCart, filterProducts };
};

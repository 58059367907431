import React from "react";
import Barner from "../Components/Home/Barner";
import img2 from "../Assets/poster2.png";
import HomeCard1 from "../Components/Home/HomeCard1";
import HomeCard2 from "../Components/Home/HomeCard2";
import Arrivals from "../Components/Home/Arrivals";
import Reviews from "../Components/Home/Reviews";

const Home = () => {
  return (
    <div style={{ zIndex: -99999 }} className=" flex flex-col gap-12 pb-10">
      <Barner />
      <Arrivals />
      <div className=" w-full sm:flex hidden justify-center mt-5 md:px-5">
        <img
          src={img2}
          className="w-full max-w-[1200px] max-h-[300px]"
          alt=""
        />
      </div>
      <Reviews />

      <div className="flex md:flex-row flex-col gap-10 md:px-10 sm:px-5 py-5">
        <div className="flex-[0.5]">
          <HomeCard1 />
        </div>
        <div className="flex-[0.5]">
          <HomeCard2 />
        </div>
      </div>
    </div>
  );
};

export default Home;

import React from "react";
import { useAppContext } from "../../MiddleWares/context/appContext";
import Product from "../Product/Product";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

const Arrivals = () => {
  const appContext = useAppContext();
  return (
    <div className="max-w-[1200px] m-auto w-full flex flex-col gap-10">
      <span className=" text-white sm:text-[40px] text-[32px]">
        New Arrivals <span></span>
      </span>

      <div className="grid grid-cols-12 md:gap-10 gap-5 w-full">
        {appContext?.newArrivals?.length > 0
          ? appContext?.newArrivals?.slice(0, 8)?.map((arrival, n) => (
              <div
                key={n}
                className="lg:col-span-3 sm:col-span-4 xsm:col-span-6 col-span-12  rounded-[10px] overflow-hidden"
              >
                <Product product={arrival} />
              </div>
            ))
          : [0, 1, 2, 3, 4, 5, 6, 7].map((_i, n) => (
              <div
                key={n}
                className="md:col-span-3 mb-3 sm:col-span-4 xsm:col-span-6 col-span-12"
              >
                <Skeleton
                  count={1}
                  height={"250px"}
                  containerClassName="w-[210px] h-[300px]"
                />
              </div>
            ))}
      </div>
      <Link
        to="/products"
        className="bg-[#4e4c4c3a] transition-all cursor-pointer ease-in-out duration-300 rounded-md hover:bg-[#000] text-[#fff] w-full flex justify-center items-center h-[50px]"
      >
        See all
      </Link>
    </div>
  );
};

export default Arrivals;

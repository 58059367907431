import ReactDOM from "react-dom";
import React, { useState } from "react";

function Test() {
  const initialLetters = ["a", "b", "c"];
  const [letters, setLetters] = useState(initialLetters);

  // Function to shuffle an array randomly
  //   const shuffleArray = (array) => {
  //     const shuffledArray = [...array];
  //     for (let i = shuffledArray.length - 1; i > 0; i--) {
  //       const j = Math.floor(Math.random() * (i + 1));
  //       [shuffledArray[i], shuffledArray[j]] = [
  //         shuffledArray[j],
  //         shuffledArray[i],
  //       ];
  //     }
  //     return shuffledArray;
  //   };

  // Function to handle button click
  //   const handleButtonClick = (letter) => {
  //     const shuffledArray = shuffleArray([...letters]);
  //     const letterIndex = shuffledArray.indexOf(letter);

  //     if (letterIndex !== -1) {
  //       // Move the clicked letter to the first position
  //       shuffledArray.splice(letterIndex, 1);
  //       shuffledArray.unshift(letter);
  //       setLetters(shuffledArray);
  //     }
  //   };

  const shuffleList = (letter) => {
    const newarr = [...letters];
    const filter = newarr.filter((a) => a !== letter);
    console.log(filter);
    setLetters([letter, ...filter]);
  };

  return (
    <div className="p-10 flex justify-center items-center bg-[#fff]">
      {letters.map((letter) => (
        <button
          className="w-[50px] border"
          key={letter}
          onClick={() => shuffleList(letter)}
        >
          {letter}
        </button>
      ))}
    </div>
  );
}

export class ButtonWithHelperText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showHelperText: false,
    };
    this.buttonRef = React.createRef();
  }

  handleButtonClick = () => {
    this.setState({ showHelperText: true }, () => {
      // Use ReactDOM.createPortal to render the helper text outside the component's DOM hierarchy
      const helperText = (
        <div className="helper-text-portal">
          Helper Text: {this.props.buttonValue}
        </div>
      );

      this.portalContainer = document.createElement("div");
      document.body.appendChild(this.portalContainer);

      ReactDOM.createPortal(helperText, this.portalContainer);

      // Close the helper text after a delay (e.g., 3 seconds)
      setTimeout(() => {
        this.closeHelperText();
      }, 3000);
    });
  };

  closeHelperText = () => {
    this.setState({ showHelperText: false }, () => {
      // Remove the portal container from the DOM
      document.body.removeChild(this.portalContainer);
    });
  };

  render() {
    return (
      <div>
        <button onClick={this.handleButtonClick} ref={this.buttonRef}>
          {this.props.buttonValue}
        </button>
        {this.state.showHelperText && null}
      </div>
    );
  }
}

// export default ButtonWithHelperText;
export default Test;
// This code defines a React functional component named App. It initializes the state with the initial a

// import React from 'react';

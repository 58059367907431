import React, { useEffect, useState } from "react";
import Loader from "../ui/Loader";
import { useNavigate, useParams } from "react-router-dom";
import Error from "./Error";
import Error_ from "../ui/error";
import useOrderService from "../MiddleWares/services/orderService";
import Success from "../ui/success";

const VerifyPayment = () => {
  const route = useParams();
  const path = window.location.href;
  const { paymentVerification } = useOrderService();
  const ref = path.slice(path.indexOf("reference"), path.length).slice(10);

  const navigate = useNavigate();

  const [loading, setLoadng] = useState(true);
  const [success, setSuccess] = useState(false);
  // console.log(path);
  const verifyPayment = async () => {
    if (route.ref === "verify-payment" && localStorage.payment_token) {
      setLoadng(true);
      const res = await paymentVerification(ref);
      console.log("res", res);
      if (res?.message === "success") {
        setSuccess(true);
        navigate(`/orders/order/${res.data._id}`);
      }
      setLoadng(false);
    }
  };

  useEffect(() => {
    verifyPayment();
  }, [path]);
  return !route.ref === "verify-payment" ? (
    <Error />
  ) : (
    <div className=" min-h-[399px]">
      <div className="max-w-[1200px] px-5 m-auto w-full flex min-h-[300px] items-center justify-center">
        <div
          action=""
          className="max-w-[500px] bg-[#80808034] min-h-[100px] p-5 gap-5 flex flex-col  w-full justify-center items-center"
        >
          {loading ? (
            <>
              <span className="text-[#fff] font-[600]">Verifying payment</span>
              <Loader size={[50, 50]} color={"#fff"} />
            </>
          ) : success ? (
            <>
              <Success />
              <span>Payment verified</span>
            </>
          ) : (
            <div>
              <Error_ />
              <span className="text-[#fff]">
                Verification Failed. Try again
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerifyPayment;

import React from "react";

const FooterCard = ({ img, title, text }) => {
  return (
    <div className="flex flex-col min-w-[170px] max-w-[250px] text-center gap-2 justify-center items-center flex-[25%]">
      <div className="">
        <img src={img} alt="" />
      </div>
      <h2 className="text-[16px]  font-semibold text-[#fff]">{title}</h2>
      <p className="text-[14px] text-[#888] leading-4">{text}</p>
    </div>
  );
};

export default FooterCard;

import React from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import locator from "../../Assets/locator.png";

const containerStyle = {
  width: "100%",
  height: "400px",
  minWidth: "300px",
  borderRadius: "24px",
};

const center = {
  lat: 6.46033,
  lng: 3.19259,
};
const mapOptions = {
  panControl: false,
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  //   overviewMapControl: false,
  rotateControl: false,
};

function Map1() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCZRuDLUX02epgwXY46WHTjGqec-647lTU",
  });
  // eslint-disable-next-line
  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);
  // console.log(isLoaded);
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={17}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={mapOptions}
    >
      <Marker options={{ icon: locator }} position={center} />
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(Map1);

import Home from "./Pages/Home";
import "./slick-style/slick_.css";
import "./slick-style/slick-theme_.css";
import "react-loading-skeleton/dist/skeleton.css";
import Footer from "./Components/Footer";
import Header from "./Components/Header/Header";
import Product from "./Pages/Product";
import Cart from "./Pages/Cart";
import Category from "./Pages/Category";
import Login from "./Pages/Login";
import Signup from "./Pages/Signup";
import FgPassword from "./Pages/FgPassword";
import RsPassword from "./Pages/RsPassword";
import Checkout from "./Pages/Checkout";
import {  Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { useData } from "./Hooks/fetchData";
import VerifyAccount from "./Pages/VerifyAccount";
import RegistrationSuccess from "./Pages/RegistrationSuccess";
import VerifyPayment from "./Pages/VerifyPayment";
import Error from "./Pages/Error";
import Order from "./Pages/Order";
import Orders from "./Pages/Orders";
import Test from "./Pages/test";
import About from "./Pages/About";

function App() {
  const { fetchCart, fetchProducts } = useData();
  useEffect(() => {
    fetchProducts();
    fetchCart();
  }, []);
  // useEffect(() => {
  //   localStorage.clear();
  // }, []);
  return (
    <div className="app m-auto max-w-[100vw]">
      <div className="px-5">
        <Header />
      </div>
      <div className="  px-5 sm:pl-5">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/shopping-cart" element={<Cart />} />
          <Route path="/products/*" element={<Category />} />
          <Route path="/catgory/:product" element={<Category />} />
          <Route path="/product/:id" element={<Product />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/forgot-password" element={<FgPassword />} />
          <Route path="/reset-password/:token" element={<RsPassword />} />
          <Route path="/test" element={<Test />} />
          <Route
            path="/registration-success"
            element={<RegistrationSuccess />}
          />
          <Route path="/verify-account/:token" element={<VerifyAccount />} />
          <Route path="/:ref" element={<VerifyPayment />} />
          <Route path="/orders" element={<Orders />}>
            <Route path="order/:id" element={<Order />} />
          </Route>
          <Route path="*" element={<Error />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;

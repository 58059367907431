import React from "react";
import celeb from "../Assets/celebration.svg";
import { Link } from "react-router-dom";

const RegistrationSuccess = () => {
  return (
    <div className=" sm:min-h-[399px]">
      <div className="max-w-[1200px] m-auto w-full flex sm:min-h-[400px] min-h-[500px] items-center justify-center">
        <div className="max-w-[500px] w-full flex flex-col gap-5">
          <div className="flex flex-col text-white gap-4 justify-center items-center p-5 bg-[#80808028] rounded-[20px] ">
            <img className=" w-[100px] " src={celeb} alt="" />
            <span className=" text-center text-[14px] leading-[24px] font-[400]">
              An email verification has been sent to{" "}
              <span className="underline text-blue-500">
                {localStorage.ver_email}
              </span>
              <br />
              <span className="text-center">
                Please complete your registration by verifying your email
                address.
              </span>
            </span>
            <Link
              className="text-[0.9rem] m-auto w-full cursor-pointer bg-[--red] max-w-[150px] flex items-center justify-center rounded-[100px] h-[40px]"
              to="/"
            >
              Back Home
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationSuccess;

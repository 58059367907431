import React from "react";
import Loader from "./Loader";

const Button = ({ loading, handleSubmit, label, classes, bg }) => {
  return (
    <span
      onClick={() => {
        !loading && handleSubmit();
      }}
      className={
        `${
          !bg && "bg-[--red]"
        } flex cursor-pointer rounded-[100px] red_shadow justify-center items-center ease-in-out duration-300 ` +
        classes
      }
    >
      {loading ? <Loader color={"#fff"} size={[15, 15]} /> : label}
    </span>
  );
};

export default Button;

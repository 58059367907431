import React, { useCallback, useEffect, useState } from "react";
import Slider from "react-slick";
import ProductCard from "../Components/Product/Product";
import { FaRegStar } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { productSevice } from "../MiddleWares/services/productService";
import { cartSevice } from "../MiddleWares/services/cartService";
import { useAppContext } from "../MiddleWares/context/appContext";
import Loader from "../ui/Loader";
import "../Components/Product/Product.scss";
import Skeleton from "react-loading-skeleton";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Product = () => {
  const productService = new productSevice();
  const cartService = new cartSevice();
  const appContext = useAppContext();
  const route = useParams();
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingCount, setLoadingCount] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const cart = appContext?.cart?.cartItems?.filter(
    (i) => i.product.id === product?._id
  );

  const [quantity, setQuantity] = useState(
    cart && cart?.length !== 0 ? cart[0]?.quantity : 0
  );

  const count = cart && cart?.length !== 0 ? cart[0]?.quantity : 0;

  const loadTrigger = pageLoading || Object.keys(product).length === 0;

  const fetchProduct = useCallback(async () => {
    setPageLoading(true);
    const res = await productService.getProduct(route?.id);
    if (res.message === "success") {
      setProduct(res.data);
      setQuantity(count);
    }
    setPageLoading(false);
  }, [route?.id]);

  const toPrice = (amount) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    });
    return formatter.format(amount);
  };

  const addToCart = async () => {
    setLoading(true);
    if (localStorage.esp_token) {
      const res = await cartService.addToCart(product._id, 1);
      if (res.message === "success") {
        await cartService.fetchCart();
        setQuantity(1);
      }
    } else {
      cartService.addToLocalCart(product, 1);
      cartService.fetchLocalCart();
      setQuantity(1);
    }
    setLoading(false);
  };

  const updateCart = async (action) => {
    setLoadingCount(true);
    if (localStorage.esp_token) {
      if (action === "plus") {
        await cartService.addToCart(product?._id, quantity + 1);
        setQuantity(quantity + 1);
      } else {
        console.log(quantity);
        quantity > 1
          ? await cartService.addToLocalCart(product?._d, quantity - 1)
          : await cartService.deleteCartItem(cart._id, product._id);
        quantity > 1 ? setQuantity(quantity - 1) : setQuantity(0);
      }
    } else {
      if (action === "plus") {
        cartService.addToLocalCart(product, quantity + 1);
        setQuantity(quantity + 1);
      } else {
        quantity > 1
          ? cartService.addToLocalCart(product, quantity - 1)
          : cartService.deleteLocalCart();
        quantity > 1 ? setQuantity(quantity - 1) : setQuantity(0);
      }
    }
    setLoadingCount(false);
  };

  useEffect(() => {
    if (product?._id !== route?.id) fetchProduct();
  }, [route?.id]);
  return (
    <div className="min-h-[700px] pb-10 w-full">
      <div className="max-w-[1200px] text-white flex  w-full items-center m-auto sm:gap-5 gap-2">
        <span className=" text-white sm:text-[40px] text-[24px]">
          New Arrivals <span></span>
        </span>
        <span>/</span>
        <span className="text-gray-400 capitalize">
          {product?.category?.category_name}
        </span>
      </div>

      <div className="grid grid-cols-12 mt-5 items-center m-auto max-w-[1200px] justify-center w-full">
        <div className="md:col-span-5 col-span-12 md:h-[450px] overflow-hidden sm:h-[300px] h-[200px]  flex justify-center items-center bg-gray-50 rounded-[20px] flex-[0.5]">
          {!loadTrigger ? (
            <div
              style={{
                backgroundImage: `url(${product?.image})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
              className="w-full md:h-[450px] rounded-[20px] h-full"
            ></div>
          ) : (
            <Skeleton
              height={"450px"}
              containerClassName=" w-[calc(40/100*100vw)]"
            />
          )}
        </div>
        <div className="md:col-span-7 text-white col-span-12 md:px-10 sm:px-5 px-0 flex flex-col sm:gap-5 gap-2">
          <span className="text-[2rem]  font-[700] capitalize sm:text-start text-center">
            {!loadTrigger ? (
              product?.title
            ) : (
              <Skeleton height={40} width={300} />
            )}
          </span>
          <div className="flex gap-1 sm:justify-start justify-center">
            <FaRegStar
              className={`${
                product?.rating > 0 ? "text-orange-500" : "text-gray-300"
              } text-[0.8rem]`}
            />
            <FaRegStar
              className={`${
                product?.rating > 1 ? "text-orange-500" : "text-gray-300"
              } text-[0.8rem]`}
            />
            <FaRegStar
              className={`${
                product?.rating > 2 ? "text-orange-500" : "text-gray-300"
              } text-[0.8rem]`}
            />
            <FaRegStar
              className={`${
                product?.rating > 3 ? "text-orange-500" : "text-gray-300"
              } text-[0.8rem]`}
            />
            <FaRegStar
              className={`${
                product?.rating > 4 ? "text-orange-500" : "text-gray-300"
              } text-[0.8rem]`}
            />
          </div>
          <span className=" sm:text-start text-center">
            {!loadTrigger ? (
              toPrice(product?.price)
            ) : (
              <Skeleton height={30} width={300} />
            )}
          </span>
          <div className="flex justify-between max-w-[150px] relative">
            {product?.offer && (
              <span className="bg-[--red] text-[0.8rem] rounded-sm px-1 text-white">
                {product?.offer}% off
              </span>
            )}
          </div>
          <blockquote className=" sm:px-0 px-3 sm:leading-8 leading-6 sm:text-start text-center">
            {!loadTrigger ? (
              product?.description
            ) : (
              <Skeleton
                height={100}
                containerClassName="sm:w-[calc(60/100*100vw)] w-[95vw]"
              />
            )}
          </blockquote>
          <hr />
          <div className="px-5 flex sm:flex-col flex-row sm:justify-start justify-center gap-3 sm:items-start items-center">
            <span className="font-semibold">Qauntity</span>
            <div className="flex gap-5 items-center">
              <span
                onClick={() => updateCart("minus")}
                className="bg-[#fff] text-[--dark] h-6 cursor-pointer rounded-sm px-3"
              >
                -
              </span>
              <span className="text-[1.5rem] text-[--red]">
                {loadingCount ? (
                  <Loader color={"--red"} size={[15, 15]} />
                ) : (
                  quantity
                )}
              </span>
              <span
                onClick={() => updateCart("plus")}
                className="bg-[#fff] text-[--dark] h-6 cursor-pointer rounded-sm px-3"
              >
                +
              </span>
            </div>
          </div>
          <div className="sm:px-0 px-5">
            <span
              onClick={() => cart && cart.length === 0 && addToCart()}
              className="bg-[--red] ease-in-out duration-500 sm:max-w-[180px] h-[50px] text-white flex justify-center items-center py-2 rounded-[100px] red_shadow  cursor-pointer"
            >
              {loading ? (
                <Loader color={"#ffffff"} size={[15, 15]} />
              ) : cart && cart?.length > 0 ? (
                "In Cart"
              ) : (
                "Add To Cart"
              )}
            </span>
          </div>
        </div>
      </div>

      <div className="max-w-[1200px] mt-[60px] h-[380px] m-auto w-full  flex flex-col gap-5">
        <span className=" text-white sm:text-[40px] text-[32px] ">
          Related Products
        </span>
        <div className="px-2">
          <Slider {...settings}>
            {appContext?.products?.map((pr, n) => (
              <div key={n} className="xsm:w-fit w-[300px] h-[300px]">
                <ProductCard product={pr} />
              </div>
            ))}
          </Slider>
          {appContext?.products?.filter(
            (p) =>
              p?.category?.category_name === product?.category?.category_name
          )?.length > 0 ? (
            <div className=""></div>
          ) : (
            <Skeleton
              height={280}
              containerClassName="w-[calc(97/100*100vw)] "
            />
          )}
        </div>
      </div>
    </div>
  );
};
const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  autoplay: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
export default Product;

import React, { useState } from "react";
import Button from "../ui/Button";
import { userSevice } from "../MiddleWares/services/userService";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";

const RsPassword = () => {
  const userService = new userSevice();
  const navigate = useNavigate();
  const [form, setForm] = useState({
    password: "",
    cf_password: "",
  });
  const [error, setError] = useState({
    password: "",
    cf_password: "",
  });
  const [loading, setLoading] = useState(false);

  const route = useParams();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    if (value === "") setError({ ...error, [name]: "Required*" });
    else if (name === "password" && value.length < 8)
      setError({
        ...error,
        [name]: "Should not be less than 8 characters*",
      });
    else if (name === "cf_password" && form.password !== value)
      setError({ ...error, [name]: "Passwords do not match*" });
    else setError({ ...error, [name]: "" });
  };
  const validateForm = () => {
    const newError = {};
    if (form.password === "") newError.password = "Required*";
    else if (form.password.length < 8)
      newError.password = "Should not be less than 8 characters*";
    if (form.cf_password === "") newError.cf_password = "Required*";
    else if (form.password !== form.cf_password)
      newError.cf_password = "Passwords do not match*";
    return newError;
  };
  const handleSubmit = async () => {
    if (Object.keys(validateForm()).length > 0) {
      setError({ ...error, ...validateForm() });
    } else {
      setLoading(true);
      const res = await userService.resetPassword({
        token: route.token,
        password: form.password,
        cf_password: form.cf_password,
      });
      if (res.message === "success") {
        toast.success("Password changed successfully");
        navigate("/login");
      }
      setLoading(false);
    }
  };
  return (
    <div className=" min-h-[399px]">
      <div className="max-w-[1200px] m-auto w-full flex min-h-[500px] items-center justify-center">
        <form action="" className="max-w-[400px] w-full flex flex-col gap-5">
          <div className="flex flex-col ">
            <span className="text-[#fff] font-[700] sm:text-[36px] text-[32px]">
              Reset Password
            </span>
            <span className="text-[0.8rem] text-gray-300">
              Enter a new password
            </span>
          </div>
          <div className="flex flex-col gap-3">
            <label className="text-[#fff] text-[0.8rem]" htmlFor="password">
              New Password*
            </label>
            <input
              type="password"
              value={form.password}
              name="password"
              onChange={handleChange}
              className={`border ${
                error.password && error.password !== ""
                  ? "border-[--red]"
                  : " border-gray-300"
              } text-gray-600 h-[45px] rounded-[100px] px-5 p-1 outline-none`}
            />
            <span className="text-[0.8rem] text-[--red]">{error.password}</span>
          </div>
          <div className="flex flex-col gap-3">
            <label className="text-[#fff] text-[0.8rem]" htmlFor="password">
              Confirm Password*
            </label>
            <input
              type="password"
              value={form.cf_password}
              name="cf_password"
              onChange={handleChange}
              className={`border ${
                error.cf_password && error.cf_password !== ""
                  ? "border-[--red]"
                  : " border-gray-300"
              } text-gray-600 h-[45px] rounded-[100px] px-5 p-1 outline-none`}
            />
            <span className="text-[0.8rem] text-[--red]">
              {error.cf_password}
            </span>
          </div>
          <Button
            label={"Submit"}
            classes={"text-white h-[50px]"}
            loading={loading}
            handleSubmit={handleSubmit}
          />
        </form>
      </div>
    </div>
  );
};

export default RsPassword;

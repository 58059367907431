import React, { useState } from "react";
import { MdDelete } from "react-icons/md";
import { BsFillBasketFill } from "react-icons/bs";
import { useAppContext } from "../MiddleWares/context/appContext";
import Loader from "../ui/Loader";
import RecipientForm from "../Components/Checkout/RecipientForm";
import useOrderService from "../MiddleWares/services/orderService";
import { toast } from "react-hot-toast";
import useProfile from "../Hooks/useProfile";
import Button from "../ui/Button";
import { useNavigate } from "react-router-dom";

const Checkout = () => {
  const appContext = useAppContext();
  const orderService = useOrderService();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [recipient, setRecipient] = useState(false);
  const toPrice = (amount) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    });
    return formatter.format(amount);
  };

  const handlePayment = async () => {
    setLoading(true);
    await orderService.initiatePayment(appContext?.cart?._id, false);
    setLoading(false);
  };
  
  const { deleteCartItem, loadingDelete } = useProfile();

  return (
    <div className=" flex flex-col items-center max-w-[1200px] m-auto justify-center">
      <div className="grid grid-cols-12 py-5 justify-center rounded-[20px] items-center h-full md:gap-10 gap-5 w-full ">
        <div className=" md:col-span-7 col-span-12  md:max-w-[500px] flex ">
          <RecipientForm setRecipient={setRecipient} recipient={recipient} />
        </div>
        <div className=" md:col-span-5 col-span-12 w-full m-auto relative md:border-l h-full pl-5">
          <span className="text-[#fff] text-center flex justify-center w-full md:text-[36px] text-[32px] mb-5">
            Current Cart
          </span>
          {appContext?.cart?.cartItems?.length > 0 ? (
            <div className="flex flex-col gap-5 mt-5">
              <div className="flex flex-col gap-3 md:max-h-[400px] max-w-[350px] m-auto w-full h-fit md:overflow-y-auto ">
                <div className="flex flex-col gap-3">
                  {appContext?.cart?.cartItems?.map((item, n) => {
                    return (
                      <div
                        key={n}
                        className="flex gap-3 justify-between items-center"
                      >
                        <div className="flex gap-3 items-center">
                          <img
                            className="w-[45px] h-[45px] rounded-[12px] col-span-3"
                            src={item.product.image}
                            alt=""
                          />
                          <div className="flex flex-col col-span-8">
                            <span className="text-[14px] text-[#fff] capitalize font-[500]">
                              {item?.product.title}
                            </span>
                            <span className=" whitespace-nowrap text-[#fff] opacity-[0.5] text-[0.6rem]">
                              {item?.quantity} X {toPrice(item.product.price)}
                            </span>
                          </div>
                        </div>
                        {loadingDelete ? (
                          <Loader color={"red"} size={[25, 25]} />
                        ) : (
                          <MdDelete
                            onClick={() =>
                              deleteCartItem(appContext?.cart?._id, item)
                            }
                            className="text-[#FF0018] cursor-pointer col-span-1"
                          />
                        )}
                      </div>
                    );
                  })}
                  <Button
                    label={"Complete Payment"}
                    handleSubmit={() => {
                      if (localStorage.esp_token) {
                        localStorage.recipient && recipient
                          ? handlePayment()
                          : toast.error(
                              "Please complete the recipient details"
                            );
                      } else navigate("/login");
                    }}
                    loading={loading}
                    classes={"h-[35px] w-full text-[#fff] text-[12px]"}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className=" h-[300px] w-full mt-[30px] bg-[#ffffff1c] flex gap-5 flex-col justify-center items-center ">
              <span className="text-[27px]  leading-[36px] text-[#fff] opacity-[0.7]">
                Your cart is empty
              </span>
              <span className="w-[80px] h-[80px] bg-[#ffffff91] rounded-full flex justify-center items-center">
                <BsFillBasketFill className=" text-[30px] text-[--red]" />
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Checkout;

import { toast } from "react-hot-toast";
import { useAppContext } from "../context/appContext";

export class cartSevice {
   baseURL = `https://esp-api.azurewebsites.net`;
  //  baseURL = `https://vast-puce-cricket-vest.cyclic.app`;
  // baseURL = `http://localhost:2000`;
  state = useAppContext();

  fetchCart = async () => {
    try {
      const res = await fetch(this.baseURL + `/carts/get-carts`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.esp_token}`,
        },
      });

      const response = await res.json();
      // console.log("response", response);
      this.state.setCart(response.data);
      return { message: "success", data: response?.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
  fetchLocalCart = async () => {
    try {
      const res = localStorage.localCart;
      if (res) {
        this.state.setCart(JSON.parse(res));
      } else {
        localStorage.setItem(
          "localCart",
          JSON.stringify({
            cartItems: [],
            total: 0,
          })
        );
        this.state.setCart({
          cartItems: [],
          total: 0,
        });
      }
    } catch (err) {
      return { message: "failed" };
    }
  };
  addToCart = async (product_id, quantity) => {
    try {
      const res = await fetch(this.baseURL + `/carts/add`, {
        method: "POST",
        body: JSON.stringify({
          product_id: product_id,
          quantity: quantity,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.esp_token}`,
        },
      });
      await res.json();
      // console.log(response);
      await this.fetchCart();
      toast.success("Added to cart");
      return { message: "success" };
    } catch (err) {
      toast.error("Failed to create product");
      return { message: "failed" };
    }
  };
  addToLocalCart = async (product, quantity) => {
    const localCart = localStorage.localCart
      ? JSON.parse(localStorage.localCart)
      : {
          cartItems: [],
          total: 0,
        };
    const cartItems = localCart?.cartItems;
    const cart = cartItems?.find((item) => item.product.id === product._id);

    if (!cart) {
      cartItems?.push({
        product: {
          id: product._id,
          image: product.image,
          price: product.price,
          title: product.title,
        },
        quantity: quantity,
      });
      let total = 0;
      cartItems?.forEach((f) => {
        let t = parseInt(f.product.price) * parseInt(f.quantity);
        total = total + t;
      });
      localStorage.setItem(
        "localCart",
        JSON.stringify({
          cartItems: cartItems,
          total: total,
        })
      );
      this.fetchLocalCart();
      toast.success("Added to cart");
    } else {
      const filter = cartItems.filter(
        (item) => item.product.id !== cart.product.id
      );
      filter.push({
        product: {
          id: product._id,
          image: product.image,
          price: product.price,
          title: product.title,
        },
        quantity: quantity,
      });
      let total = 0;
      filter.forEach((f) => {
        let t = parseInt(f.product.price) * parseInt(f.quantity);
        total = total + t;
      });
      localStorage.setItem(
        "localCart",
        JSON.stringify({
          cartItems: filter,
          total: total,
        })
      );
    }
  };
  deleteCartItem = async (cart_id, item_id) => {
    try {
      const res = await fetch(
        this.baseURL + `/carts/delete-cart/${cart_id}/${item_id}`,
        {
          method: "DELETE",
          headers: { Authorization: `Bearer ${localStorage.esp_token}` },
        }
      );
      const response = await res.json();
      if (response?.message === "success") {
        toast.success("Item deleted");
        await this.fetchCart();
      } else toast.error("Failed to delete item");
    } catch (err) {
      toast.error("Failed to delete item");
    }
  };
  deleteLocalCart = (product) => {
    console.log(product);
    const localCart = JSON.parse(localStorage.localCart);
    const filter = localCart?.cartItems?.filter(
      (item) => item.product.id !== product.id
    );
    // console.log(filter);
    let total = 0;
    filter?.forEach((f) => {
      let t = parseInt(f?.product?.price) * parseInt(f.quantity);
      total = total + t;
    });

    localStorage.setItem(
      "localCart",
      JSON.stringify({
        cartItems: filter,
        total: total,
      })
    );

    this.fetchLocalCart();
    toast.success("Item removed from your cart");
  };
}

import { toast } from "react-hot-toast";
import { useAppContext } from "../context/appContext";
import { useNavigate } from "react-router-dom";
import { cartSevice } from "./cartService";

export class authSevice {
  baseURL = `https://esp-api.azurewebsites.net`;
  // baseURL = `https://vast-puce-cricket-vest.cyclic.app`;
  // baseURL = `http://localhost:2000`;
  state = useAppContext();
  navigate = useNavigate();
  cartService = new cartSevice();

  login = async (form) => {
    try {
      const res = await fetch(this.baseURL + `/user/login`, {
        method: "POST",
        body: JSON.stringify({ email: form.email, password: form.password }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const response = await res.json();
      if (response.message === "success") {
        // console.log(response);
        localStorage.setItem("esp_token", response.token);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        if (localStorage.localCart) {
          const cart = JSON.parse(localStorage.localCart);
          cart.cartItems.forEach(async (item) => {
            await this.cartService.addToCart(item.product.id, item.quantity);
          });
        }
        localStorage.removeItem("localCart");
        return { message: "success", data: response };
      } else if (response.status && response.status === 401) {
        toast.error("Invalid email or password");
        return { message: "failed" };
      } else {
        toast.error("Something went wrong, Please try again");
        return { message: "failed" };
      }
    } catch (err) {
      toast.error("Unable to login!");
      return { message: "failed" };
    }
  };
  signup = async (form) => {
    try {
      const res = await fetch(this.baseURL + "/user/register", {
        method: "POST",
        body: JSON.stringify(form),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const response = await res.json();
      // console.log(response);
      if (response.message === "User already exist") {
        toast.error(response.message);
        this.navigate("/login");
        return { message: response.message };
      } else if (response.message === "success") {
        toast.success("Verification email sent");
        return { message: "success" };
      } else {
        toast.error("Registration failed");
        return { message: "failed" };
      }
    } catch (err) {
      toast.error("Registration failed");
      return { message: "failed" };
    }
  };
  verifyEmail = async (token) => {
    try {
      // console.log(token);
      const res = await fetch(this.baseURL + "/user/verify", {
        method: "POST",
        body: JSON.stringify({ token: token }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const response = await res.json();
      // console.log(response.message);
      if (response?.message === "User already exist") {
        toast.error(response.message);
        this.navigate("/login");
        return { message: response.message };
      } else if (response?.message === "success") {
        // console.log(response)
        toast.success("Verification successful");
        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem("esp_token", response.token);
        if (localStorage.localCart) {
          const cart = JSON.parse(localStorage.localCart);
          cart.cartItems.forEach(async (item) => {
            await this.cartService(item.product.id, item.quantity);
          });
        }
        return { message: "success" };
      } else if (
        response?.message === "Invalid Authorization token" ||
        response?.message === "Authorization token is required"
      ) {
        toast.error("verification failed");
        return { message: "failed" };
      } else {
        toast.error("Something went wrong");
        // console.log(response);
        return { message: "failed" };
      }
    } catch (err) {
      // console.log(err);
      toast.error("Registrator token has expired");
      return { message: "failed" };
    }
  };
}

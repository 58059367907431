import React from "react";
import banner from "../../Assets/bg.jpg";
import speaker from "../../Assets/bg_.png";
import "./Barner.scss";
import { motion } from "framer-motion";

const Barner = () => {
  return (
    <div className=" md:h-[600px] h-fit md:gap-5 gap-10 max-w-[1200px] w-full sm:grid flex flex-col sm:grid-cols-12 m-auto">
      <div className="w-full md:col-span-6 col-span-12 gap-4 md:mt-0 mt-10 text-white h-full flex flex-col justify-center md:items-start items-center">
        <span className="sm:text-[48px] text-[32px] sm:leading-[64px] leading-[48px] max-w-[550px] w-full md:text-start text-center">
          Emerging leaders in speaker technology.
        </span>
        <span className=" max-w-[550px] md:text-start text-center">
          Your demand for quality audio ends here as we offer rhythmic influence
          for a magical experience.
        </span>
      </div>
      <div className=" md:col-span-6 col-span-12 flex items-center justify-center">
        <img className="" src={speaker} alt="" />
      </div>
    </div>
  );
};

export default Barner;

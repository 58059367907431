import React from "react";

const ItemCard = ({ data }) => {
  const toPrice = (amount) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    });
    return formatter.format(amount);
  };
  return (
    <div className="flex sm:flex-nowrap flex-wrap gap-4  border-b pb-4 justify-between items-center">
      <div className="flex gap-x-5 gap-y-3 items-center">
        <span className="min-w-[60px] h-[60px] bg-slate-900 rounded-full">
          <img
            className="min-w-[60px] h-[60px] rounded-full"
            src={data?.product?.image}
            alt=""
          />
        </span>
        <span className="flex flex-col gap3 font-[400] capitalize md:text-[14px] text-[14px] text-[#fff]">
          {data?.product?.title}
          <span className="font-[400] text-[#fff] truncate whitespace-nowrap max-w-[200px] overflow-hidden">
            {data?.product?.description}
          </span>
        </span>
      </div>
      <span className="md:text-[14px] text-[12px] font-[300] text-[#fff]">
        {data?.quantity} X {toPrice(data?.product?.price)}
      </span>
    </div>
  );
};

export default ItemCard;

import React, { createContext, useContext, useState } from "react";

const AppContext = createContext(null);

const AppContextProvider = ({ children }) => {
  const [users, setUsers] = useState([]);
  const [products, setProducts] = useState([]);
  const [orders, setOrders] = useState([]);
  const [cart, setCart] = useState({});
  const [categories, setCategories] = useState([]);
  const [newArrivals, setNewArrivals] = useState([]);
  const [targetCategory, setTargetCategory] = useState("All products");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [order, setOrder] = useState(null);
  return (
    <AppContext.Provider
      value={{
        users,
        products,
        orders,
        cart,
        categories,
        newArrivals,
        filteredProducts,
        targetCategory,
        order,
        setOrder,
        setUsers,
        setProducts,
        setOrders,
        setCart,
        setCategories,
        setNewArrivals,
        setFilteredProducts,
        setTargetCategory,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  const context = useContext(AppContext);
  return context;
};

export { AppContextProvider, useAppContext };

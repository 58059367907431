import React from "react";
import bg1 from "../../Assets/bg_13.jpg";

function HomeCard1() {
  return (
    <div className="w-full sm:h-[200px] h-[180px] rounded-xl relative border">
      <img className="w-full h-full rounded-xl" src={bg1} alt="" />
      <div className="absolute bg-[#00000080] rounded-xl md:px-10 px-5 top-0 w-full h-full flex flex-col justify-center">
        <h2 className="lg:font-extrabold font-bold text-white h-[5rem] lg:text-[2rem] text-[1.5rem] md:leading-10">Take Control of your home with alexa</h2>
        <p className=" text-white font-thin">The vitual assistant technology</p>
      </div>
    </div>
  );
}

export default HomeCard1;

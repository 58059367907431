import React from "react";
import { BsStarFill } from "react-icons/bs";
// import avatar from "../../assets/images/avatar1.png";

const ReviewCard = ({ avatar, text, name }) => {
  return (
    <div className="flex bg-[#fff] shadow-xl gap-[16px] p-[16px] light-shadow backdrop-blur-[20px] rounded-[24px] md:w-[518px] sm:w-[443px] w-[350px]  md:h-[147px] h-[123px]">
      <img
        src={avatar}
        className="md:w-[50px] w-[40px] md:h-[50px] h-[40px] rounded-full "
        alt=""
      />
      <div className="flex flex-col w-full overflow-hidden text-ellipsis">
        <div className="flex items-center w-full justify-between">
          <span className="md:text-[18px] text-[16px] md:leading-[36px] leading-[24px] font-[500] text-gray-700 opacity-[0.86]">
            {name}
          </span>
          <div className="flex items-center gap-[10px]">
            <BsStarFill className="text-[20px] text-[#ffa503]" />
            <span className="text-[14px] leading-[21px] font-[500] text-gray-400">
              4.9
            </span>
          </div>
        </div>
        <div className="dotted_text md:h-[72px] h-[63px]">
          <span className="font-[400]  text-ellipsis text-gray-400 opacity-[0.65] md:text-[16px] text-[14px] md:leading-[24px] leading-[21px] ">
            {text}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { authSevice } from "../MiddleWares/services/authService";
import Loader from "../ui/Loader";

const Login = () => {
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  // const [termError, setTermError] = useState(false);

  const navigate = useNavigate();
  const authService = new authSevice();
  const handleChange = (e) => {
    const { value, name } = e.target;
    setForm({ ...form, [name]: value });
    if (value === "") setErrors({ ...errors, [name]: "Required*" });
    else setErrors({ ...errors, [name]: "" });
  };
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const validateForm = () => {
    const { email, password } = form;
    const error = {};
    if (email === "") error.email = "Required*";
    else if (email !== "" && !validateEmail(email))
      error.email = "Enter a valid email adddress";
    if (password === "") error.password = "Required*";
    else if (password.length < 8)
      error.password = "Passwords should be at least 8 characters";
    return error;
  };

  const handleSubmit = async () => {
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setLoading(true);
      const res = await authService.login(form);
      if (res.message === "success") {
        // console.log(res);
        localStorage.setItem("esp_token", res.data.token);
        localStorage.setItem("user", JSON.stringify(res.data.data.user));
        setLoading(false);
        localStorage.removeItem("localCart");
        navigate(-1);
      }
      setLoading(false);
    }
  };

  return (
    <div className=" min-h-[399px] py-[60px] sm:pt-[60px] pt-0">
      <div className="max-w-[1200px] m-auto w-full flex min-h-[500px] items-center justify-center">
        <form action="" className="max-w-[450px] w-full flex flex-col gap-5">
          <div className="flex flex-col ">
            <span className="text-[#fff] font-[700] sm:text-[48px] text-[32px]">
              Login
            </span>
            <span className="text-[0.8rem] text-gray-300">
              Get the best experience
            </span>
          </div>
          <div className="flex flex-col gap-3">
            <label className="text-[#fff] text-[0.8rem]" htmlFor="email">
              Email Address*
            </label>
            <input
              type="text"
              value={form.email}
              name="email"
              onChange={handleChange}
              className={`border ${
                errors.email && errors.email !== ""
                  ? "border-[--red]"
                  : " border-gray-300"
              } text-gray-600 h-[45px] rounded-[100px] px-5 p-1 outline-none`}
            />
            <span className="text-[0.8rem] text-[--red]">{errors.email}</span>
          </div>
          <div className="flex flex-col gap-3">
            <label className="text-[#fff] text-[0.8rem]" htmlFor="password">
              Password*
            </label>
            <input
              type="password"
              value={form.password}
              name="password"
              onChange={handleChange}
              className={`border ${
                errors.password && errors.password !== ""
                  ? "border-[--red]"
                  : " border-gray-300"
              } text-gray-600 px-5 p-1 h-[45px] rounded-[100px] outline-none`}
            />
            <span className="text-[0.8rem] text-[--red]">
              {errors.password}
            </span>
          </div>
          <Link
            to={"/forgot-password"}
            className="text-[#fff] italic font-thin text-[0.9rem] cursor-pointer justify-end flex"
          >
            Forgot password?
          </Link>
          <span
            onClick={handleSubmit}
            className="bg-[--red] flex cursor-pointer rounded-[100px] red_shadow justify-center items-center ease-in-out duration-300 text-white h-[50px]"
          >
            {loading ? <Loader color={"#fff"} size={[15, 15]} /> : "Submit"}
          </span>
          <span className="text-[#fff] text-[0.9rem] cursor-pointer flex gap-3">
            Don't have an account?
            <Link
              to="/signup"
              className="text-[--red] cursor-pointer font-normal"
            >
              Singup
            </Link>
          </span>
        </form>
      </div>
    </div>
  );
};

export default Login;

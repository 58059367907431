import React, { useEffect, useRef, useState } from "react";
import Product from "../Components/Product/Product";
import img1 from "../Assets/poster.png";
import { BiChevronRight } from "react-icons/bi";
import { useAppContext } from "../MiddleWares/context/appContext";
import { useData } from "../Hooks/fetchData";
import Skeleton from "react-loading-skeleton";

const Category = () => {
  const appContext = useAppContext();
  const { filterProducts } = useData();
  const [page, setPage] = useState(12);
  const [open, setOpen] = useState(false);
  const [delay, setDelay] = useState(false);
  const ref = useRef();
  const toggle = () => {
    if (!open) {
      setOpen(true);
      setTimeout(() => {
        setDelay(true);
      }, 50);
    } else {
      setDelay(false);
      setTimeout(() => {
        setOpen(false);
      }, 50);
    }
  };
  window.addEventListener("click", (e) => {
    if (delay && !ref.current?.contains(e.target)) {
      toggle();
    }
  });
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div className="pb-10 w-full  flex flex-col items-center">
        <div className="max-w-[1200px] text-white flex  w-full items-center m-auto sm:gap-5 gap-2">
          <span className=" text-white sm:text-[40px] text-[24px]">
            Products
          </span>
          <span>/</span>
          <div className="flex relative" ref={ref}>
            <span
              onClick={toggle}
              className="text-gray-400 flex items-center cursor-pointer capitalize"
            >
              {appContext?.targetCategory}
              <BiChevronRight />
            </span>
            {open && (
              <div
                className={`absolute transition-all text-[#333] ease-in-out duration-200 ${
                  delay ? " translate-y-0" : "translate-y-[-30px]"
                } flex flex-col gap-1 sm:left-5 left-0 bg-white shadow-xl w-[210px] z-40 top-6 p-2`}
              >
                <span
                  onClick={() => filterProducts("All Products")}
                  className="h-[30px] text-[0.8rem] capitalize cursor-pointer"
                >
                  All Products
                </span>
                {appContext?.categories?.map((category, n) => {
                  return (
                    <span
                      key={n}
                      onClick={() => filterProducts(category?.category_name)}
                      className="h-[30px] text-[0.8rem] capitalize cursor-pointer"
                    >
                      {category?.category_name}
                    </span>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        
        <div className="grid grid-cols-12 md:gap-10 max-w-[1200px] gap-5 w-full mt-[60px]">
          {appContext?.filteredProducts?.length > 0
            ? appContext?.filteredProducts
                ?.slice(0, page)
                ?.map((product, n) => (
                  <div
                    key={n}
                    className="md:col-span-3 sm:col-span-4 xsm:col-span-6 col-span-12"
                  >
                    <Product product={product} />
                  </div>
                ))
            : [0, 1, 2, 3, 4, 5, 6, 7].map((_i, n) => (
                <div
                  key={n}
                  className="md:col-span-3 mb-3 sm:col-span-4 col-span-6"
                >
                  <Skeleton
                    count={1}
                    height={"250px"}
                    containerClassName="w-[210px] h-[300px]"
                  />
                </div>
              ))}
          {page > 12 && (
            <span className=" col-span-12 flex justify-end">
              <span
                onClick={() => setPage(page + 12)}
                className=" text-end cursor-pointer underline text-red-600 text-[0.8rem]"
              >
                show more....
              </span>{" "}
            </span>
          )}
        </div>
      </div>
      <div className=" w-full flex justify-center my-5 px-5">
        <img
          src={img1}
          className="w-full max-w-[1200px] max-h-[300px]"
          alt=""
        />
      </div>
    </>
  );
};

export default Category;

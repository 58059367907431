import React, { useRef, useState } from "react";
import { useAppContext } from "../MiddleWares/context/appContext";
import { useNavigate } from "react-router-dom";
import { userSevice } from "../MiddleWares/services/userService";
import { cartSevice } from "../MiddleWares/services/cartService";

const useProfile = () => {
  const appContext = useAppContext();
  const navigate = useNavigate();
  const userService = new userSevice();
  const cartService = new cartSevice();
  const [openCart, setOpenCart] = useState(false);
  const [profile, setProfile] = useState(false);
  const [openDelay, setOpenDelay] = useState(false);
  const [openDelay_, setOpenDelay_] = useState(false);
  const [edit, setEdit] = useState(false);
  const toggleRef = useRef();
  const cartRef = useRef();
  const profileRef = useRef();
  const profileToggle = useRef();

  const user = localStorage.user ? JSON.parse(localStorage.user) : {};

  const [form, setForm] = useState({
    first_name: user?.first_name,
    last_name: user?.last_name,
    email: user?.email,
    phone: user?.phone ? user?.phone : "",
    house_number: user?.address?.house_number
      ? user?.address?.house_number
      : "",
    street: user?.address?.street ? user?.address?.street : "",
    state: user?.address?.state ? user?.address?.state : "",
  });
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    house_number: "",
    street: "",
    state: "",
  });
  const [image, setImage] = useState(user?.image);
  const [loading, setLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const validateEmail = (email) => {
    const match =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return match.test(email);
  };
  const handleChange = (e) => {
    const { value, name } = e.target;
    setForm({ ...form, [name]: value });
    if (value === "" && name === "first_name")
      setErrors({ ...errors, [name]: "Required*" });
    else if (value === "" && name === "last_name")
      setErrors({ ...errors, [name]: "Required*" });
    else if (value === "" && name === "email")
      setErrors({ ...errors, [name]: "Required*" });
    else if (name === "email" && !validateEmail(value))
      setErrors({ ...errors, [name]: "Invalid Email*" });
    else if (name === "phone") {
      if (value.length !== 11)
        setErrors({ ...errors, [name]: "Invalid Phone number *" });
      else setErrors({ ...errors, [name]: "" });
    } else setErrors({ ...errors, [name]: "" });
  };

  const validateForm = () => {
    const { first_name, last_name, email, phone } = form;
    const newErrors = {};

    if (first_name === "") newErrors.first_name = "Required*";
    if (last_name === "") newErrors.last_name = "Required*";
    if (email === "") newErrors.email = "Required*";
    else if (!validateEmail(email)) newErrors.email = "Invalid Email*";
    if (phone !== "" && phone.length !== 11)
      newErrors.phone = "Invalid phone nummber*";
    return newErrors;
  };
  const formData = new FormData();
  const handleSubmit = async () => {
    const hasError = validateForm();
    if (Object.keys(hasError).length > 0) {
      setErrors({ ...errors, ...hasError });
    } else {
      setLoading(true);
      await userService.updateUser({
        first_name: form.first_name,
        last_name: form.last_name,
        email: form.email,
        phone: form.phone !== "" ? form.phone : "",
        address: {
          house_number: form.house_number,
          street: form.street,
          state: "lagos",
        },
      });
      setEdit(false);
      setLoading(false);
    }
  };

  const uploadImage = async (file) => {
    setLoadingImage(true);
    formData.append("image", file);
    const res = await userService.uploadImage(user?.id, formData);
    if (res.message === "success") setImage(res.data);
    setLoadingImage(false);
  };

  const toPrice = (amount) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    });
    return formatter.format(amount);
  };

  const toggleCart = () => {
    if (openCart) {
      setOpenCart(false);
      setTimeout(() => {
        setOpenDelay(false);
      }, 200);
    } else {
      setOpenDelay(true);
      setTimeout(() => {
        setOpenCart(true);
      }, 200);
    }
  };
  const toggleProfile = () => {
    if (profile) {
      setProfile(false);
      setTimeout(() => {
        setOpenDelay_(false);
      }, 200);
    } else {
      setOpenDelay_(true);
      setTimeout(() => {
        setProfile(true);
      }, 200);
    }
  };

  const deleteCartItem = async (cart_id, item) => {
    if (localStorage.esp_token) {
      setLoadingDelete(true);
      await cartService.deleteCartItem(cart_id, item.id);
      setLoadingDelete(false);
    } else {
      cartService.deleteLocalCart(item.product);
    }
  };

  window.addEventListener("click", (e) => {
    if (
      openCart &&
      !cartRef.current?.contains(e.target) &&
      !toggleRef.current?.contains(e.target)
    ) {
      toggleCart();
    }
  });
  window.addEventListener("click", (e) => {
    if (
      profile &&
      !profileToggle.current?.contains(e.target) &&
      !profileRef.current?.contains(e.target)
    ) {
      toggleProfile();
    }
  });

  return {
    appContext,
    navigate,
    profile,
    openCart,
    openDelay,
    openDelay_,
    toggleRef,
    cartRef,
    profileRef,
    profileToggle,
    user,
    form,
    errors,
    loading,
    handleChange,
    validateForm,
    handleSubmit,
    toPrice,
    toggleCart,
    toggleProfile,
    edit,
    setEdit,
    loadingImage,
    image,
    uploadImage,
    loadingDelete,
    deleteCartItem,
  };
};

export default useProfile;

import React from "react";
import FooterCard from "./FooterCard";
import img1 from "../Assets/1.png";
import img2 from "../Assets/2.png";
import img3 from "../Assets/3.png";
import img4 from "../Assets/4.png";
import logo from "../Assets/logo2.png";
import { BsInstagram, BsTelegram, BsTwitterX } from "react-icons/bs";
import { IoLogoFacebook, IoLogoWhatsapp } from "react-icons/io";

const Footer = () => {
  return (
    <div className="w-full bg-[#000] flex flex-col gap-10 px-5">
      <div className="flex flex-wrap justify-center max-w-[1200px] w-full py-5 pt-10 md:px-10 px-5 m-auto gap-10">
        <FooterCard
          img={img1}
          title={"Delivery"}
          text={"And free returns. See checkout for delivery dates."}
        />
        <FooterCard
          img={img2}
          title={"Safe Payment"}
          text={"Pay with one of the most popular and secure payment methods."}
        />
        <FooterCard
          img={img3}
          title={"Shop with Confidence"}
          text={
            "Our Buyer Protection covers your purchasefrom click to delivery."
          }
        />
        <FooterCard
          img={img4}
          title={"24/7 Help Center"}
          text={"Have a question? Call a Specialist."}
        />
      </div>
      <hr />
      <div className="flex flex-col gap-10 justify-cemter max-w-[1200px] py-5 m-auto">
        <div className="flex flex-col text-white text-center">
          <span className="text-[2rem ] text-center font-semibold">
            Contact
          </span>
          <p className=" font-medium ">
            PS-A08, Pace setter plaza, Alaba International market, Lagos,
            Nigeria
          </p>
          <p className=" font-thin text-sm  text-gray-300">
            exquisitesoundpro@gmail.com
          </p>
          <span className=" font-thin text-gray-300 ">09060035749</span>
        </div>
        <div className="flex items-center justify-center gap-10">
          <IoLogoWhatsapp
            onClick={() => (window.location.href = "https://wa.link/6hvhff")}
            className="text-[#ffffff9a] text-[1.8rem] cursor-pointer"
          />
          <IoLogoFacebook
            onClick={() =>
              (window.location.href =
                "https://www.facebook.com/profile.php?id=100077088576963&mibextid=ZbWKwL")
            }
            className="text-[#ffffff9a] text-[1.8rem] cursor-pointer"
          />
          <BsTwitterX
            onClick={() =>
              (window.location.href =
                "https://www.facebook.com/profile.php?id=100077088576963&mibextid=ZbWKwL")
            }
            className=" text-[1.8rem] text-[#ffffff9a] cursor-pointer"
          />
          <BsInstagram
            onClick={() =>
              (window.location.href =
                "https://www.instagram.com/exquisite_soundpro?igsh=dHVkb2kyaXBzenk4")
            }
            className="text-[#ffffff9a] text-[1.6rem] cursor-pointer"
          />
          <BsTelegram
            onClick={() =>
              (window.location.href = "https://t.me/+2348028334977")
            }
            className=" text-[1.8rem] text-[#ffffff9a] cursor-pointer"
          />
        </div>
        <div className="flex flex-col justify-center max-w-[200px] m-auto">
          <img src={logo} className="h-[100px] m-auto" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Footer;

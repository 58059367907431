import React, { useEffect, useState } from "react";
import ItemCard from "../Components/Order/ItemCard";
import { useParams } from "react-router-dom";
import useOrderService from "../MiddleWares/services/orderService";
import { useAppContext } from "../MiddleWares/context/appContext";
import Loader from "../ui/Loader";

const Order = () => {
  const route = useParams();
  const { getOrderById } = useOrderService();
  const { order } = useAppContext();
  const [loading, setLoading] = useState({});
  const [order_, setOrder] = useState(order);
  const [order_id, setOrderId] = useState("");
  // const getOrder = async () => {
  //   setLoading(true);
  //   const res = await getOrderById(order.id);
  //   if (res.message === "success") {
  //     setOrder(res.data);
  //   }
  //   setLoading(false);
  // };
  const toPrice = (amount) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    });
    return formatter.format(amount);
  };

  useEffect(() => {
    // getOrder();
    if (order_id !== order._id) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setOrder(order_id);
      }, 200);
    }
  }, [route, order_id, order]);

  return loading ? (
    <div className="flex justify-center">
      <Loader size={[100, 100]} color={"#fff"} />
    </div>
  ) : (
    <div className="flex flex-col gap-4 pb-5 text-[#fff] ">
      <div className="flex flex-col gap-1 bg-[#00000081] rounded-[20px] md:p-5 w-full text-[13px] font-[600] m-auto">
        <span className="text-[21px] text-[#fff]  capitalize ">
          {order?.recipient_info?.full_name}
        </span>
        <span className=" italic text-[#fff] text-[14px] ">{`${order?.recipient_info?.address?.house_number}, ${order?.recipient_info?.address?.street}, ${order?.recipient_info?.address?.state}`}</span>
        <span className="flex justify-between items-center flex-wrap text-[14px] ">
          {order?.recipient_info?.email}{" "}
          <span>{order?.recipient_info?.phone}</span>
        </span>
      </div>{" "}
      {order?.order_details?.ordered_items?.map((item, n) => (
        <ItemCard data={item} key={n} />
      ))}
      <div className="flex flex-col bg-white shadow-xl p-5 font-[400] text-[14px] text-gray-500">
        <span className="flex justify-between items-center">
          Items Total <span>{toPrice(order?.order_details?.total)}</span>
        </span>
        <span className="flex justify-between items-center">
          Delevery Charge <span>----</span>
        </span>
        <span className="flex justify-between items-center">
          Vat Charge <span>----</span>
        </span>{" "}
        <span className="flex justify-between items-center">
          Total{" "}
          <span className=" underline">
            {toPrice(order?.order_details?.total)}
          </span>
        </span>
      </div>
    </div>
  );
};

export default Order;

import React from "react";
import "./reviews.scss";
import { homeReviews } from "../../Constants/reviews";
import ReviewCard from "./ReviewCard";

const Reviews = () => {
  return (
    <div className="w-full mini-tablet:h-[1007px] mini-tablet:py-[200px] py-[88px] flex flex-col gap-[48px] justify-center items-center">
      <div className="flex flex-col justify-center items-center">
        <span className="md:text-[48px] sm:text-[32px] text-[24px] font-[500] md:leading-[72px] sm:leading-[48px] leading-[36px] text-center md:max-w-[505px] sm:max-w-[337px] max-w-[253px] text-[#fff]">
          What our customers say about us
        </span>
        <span className="text-[16px] px-[16px] font-[400] leading-[19px] text-center  md:max-w-[629px] max-w-[700px] text-[#fff] opacity-[0.65] mt-[16px]">
          We give the best product and service! What we care about is the WOW
          effect and all the luck that our efforts are not in vain. We know
          because we listen to our customers.
        </span>
      </div>
      <div className="slider overflow-hidden">
        <div className="slide_track">
          {homeReviews?.map((r, n) => (
            <div key={n} className="slide">
              <ReviewCard avatar={r.image} name={r.name} text={r.text} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Reviews;

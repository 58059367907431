export const homeReviews = [
  {
    image: require("../Assets/reviewers/ava_g.png"),
    name: "George Freeman",
    text: "Their products are absolutely amazing! I ordered sound equipments for my studio and it was all perfect. The service was excellent too, Highly recommend!",
  },
  {
    image: require("../Assets/reviewers/ava_k.png"),
    name: "Kate Griffon",
    text: "Omgggg, you could tell that the speakers are legit by its sound. I enjoyed every bit of it with my siblings.",
  },
  {
    image: require("../Assets/reviewers/ava_en.png"),
    name: "Eren Yeager",
    text: "Exquisite sound pro is my go-to delivery platform. Their prices are really reasonable. Plus, the app is super easy to use.",
  },
  {
    image: require("../Assets/reviewers/ava_g.png"),
    name: "Gwen Stacey",
    text: "Hi! So I just had alexa installed alexa in my home! controlling home gadgets have never been this easy.  would definitely definistly recommend.",
  },
  {
    image: require("../Assets/reviewers/ava_l.png"),
    name: "Luffy Derick",
    text: "Their products are absolutely amazing! I ordered sound equipments for my studio and it was all perfect. The service was excellent too, Highly recommend!",
  },
  {
    image: require("../Assets/reviewers/ava_b.png"),
    name: "Babatunde Raji",
    text: "Omgggg, you could tell that the speakers are legit by its sound. I enjoyed every bit of it with my siblings.",
  },
  {
    image: require("../Assets/reviewers/ava_g.png"),
    name: "Gwen Stacey",
    text: "Exquisite sound pro is my go-to delivery platform. Their prices are really reasonable. Plus, the app is super easy to use.",
 },
  {
    image: require("../Assets/reviewers/ava_l.png"),
    name: "Luffy Derick",
    text: "Hi! So I just had alexa installed alexa in my home! controlling home gadgets have never been this easy.  would definitely definistly recommend.",
  },
  {
    image: require("../Assets/reviewers/ava_b.png"),
    name: "Babatunde Raji",
    text: "Exquisite sound pro is my go-to delivery platform. Their prices are really reasonable. Plus, the app is super easy to use.",
 },
  {
    image: require("../Assets/reviewers/ava_en.png"),
    name: "Evan Steff",
    text: "Omgggg, you could tell that the speakers are legit by its sound. I enjoyed every bit of it with my siblings.",
  },
];

import React from "react";
import img from "../Assets/Sample/sample1.png";

const Cart = () => {
  return (
    <div className="pb-10 w-full overflow-hidden">
      <div className="bg-gray-300 md:pl-20 px-5 w-full text-gray-600 py-3">
        <div className="max-w-[1200px] flex gap-5">
          <span>User</span>
          <span>/</span>
          <span className="text-gray-400">Shopping Cart</span>
        </div>
      </div>
      <div className="max-w-[1200px] m-auto overflow-x-auto">
        <div className="flex flex-col mt-5">
          <div className="min-w-[900px] ">
            <div className="bg-gray-200 w-full flex py-3 ">
              <span className="text-[0.8rem] text-gray-700 flex-[0.25] text-center">
                Image
              </span>
              <span className="text-[0.8rem] text-gray-700 flex-[0.25] text-center">
                Products
              </span>
              <span className="text-[0.8rem] text-gray-700 flex-[0.25] text-center">
                Unit Price
              </span>
              <span className="text-[0.8rem] text-gray-700 flex-[0.25] text-center">
                Qauntity
              </span>
              <span className="text-[0.8rem] text-gray-700 flex-[0.25] text-center">
                Total
              </span>
            </div>
            <div className="w-full flex py-3 items-center relative">
              <span className="text-[0.8rem] text-gray-700 flex-[0.25] flex justify-center">
                <img src={img} className={"max-w-[100px]"} alt="" />
              </span>
              <span className="text-[0.8rem] text-gray-700 flex-[0.25] text-center">
                Cieling speaker
              </span>
              <span className="text-[0.8rem] text-gray-700 flex-[0.25] text-center">
                N 145000
              </span>
              <span className="text-[0.8rem] text-gray-700 flex-[0.25] flex justify-center">
                <div className="flex gap-5 items-center">
                  <span className="bg-gray-900 flex items-center h-6 cursor-pointer rounded-sm px-3 text-white">
                    -
                  </span>
                  <span className="text-[1.5rem] text-gray-600">0</span>
                  <span className="bg-gray-900 flex items-center h-6 cursor-pointer rounded-sm px-3 text-white">
                    +
                  </span>
                </div>
              </span>
              <span className="text-[0.8rem] text-gray-700 flex-[0.25] text-center">
                N 290000
              </span>
            </div>
            <hr />
            <div className="w-full flex py-3 items-center relative">
              <span className="text-[0.8rem] text-gray-700 flex-[0.25] flex justify-center">
                <img src={img} className={"max-w-[100px]"} alt="" />
              </span>
              <span className="text-[0.8rem] text-gray-700 flex-[0.25] text-center">
                Cieling speaker
              </span>
              <span className="text-[0.8rem] text-gray-700 flex-[0.25] text-center">
                N 145000
              </span>
              <span className="text-[0.8rem] text-gray-700 flex-[0.25] flex justify-center">
                <div className="flex gap-5 items-center">
                  <span className="bg-gray-900 flex items-center h-6 cursor-pointer rounded-sm px-3 text-white">
                    -
                  </span>
                  <span className="text-[1.5rem] text-gray-600">0</span>
                  <span className="bg-gray-900 flex items-center h-6 cursor-pointer rounded-sm px-3 text-white">
                    +
                  </span>
                </div>
              </span>
              <span className="text-[0.8rem] text-gray-700 flex-[0.25] text-center">
                N 290000
              </span>
            </div>
            <hr />
          </div>
        </div>
      </div>
      <div className="flex justify-end w-full">
        <div className="max-w-[400px] w-full md:px-20 px-5">
          <div className="flex gap-20 py-5 bg-gray-50 px-5">
            <span>Total: </span>
            <span>N 580000</span>
          </div>
          <button className="bg-gray-400 hover:bg-gray-900 hover:text-white ease-in-out duration-500 py-2 max-w-[400px] w-full">
            Proceed To Checkout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cart;

import { toast } from "react-hot-toast";
import { useAppContext } from "../context/appContext";
import { cartSevice } from "./cartService";

const useOrderService = () => {
  const baseURL = `https://esp-api.azurewebsites.net`;
  // const baseURL = `https://vast-puce-cricket-vest.cyclic.app`;
  // const baseURL = `http://localhost:2000`;
  const state = useAppContext();
  const recipient = localStorage.recipient
    ? JSON.parse(localStorage.recipient)
    : {};
  const cartService = new cartSevice();
  console.log(recipient);
  const initiatePayment = async (cart_id, delivery) => {
    // console.log(cart_id);
    const response = await fetch(baseURL + `/order/create-order`, {
      method: "POST",
      body: JSON.stringify({
        full_name: recipient?.first_name + " " + recipient?.last_name,
        email: recipient?.email,
        phone: recipient?.phone,
        house_number: recipient?.house_number,
        street: recipient?.street,
        state: recipient?.state,
        cart_id: cart_id,
        delivery: delivery,
        payment_option: "card",
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.esp_token}`,
      },
    });
    const res = await response.json();
    // console.log(res);
    if (res.status === 200) {
      window.location = res.data.paystack_url;
      localStorage.setItem("payment_token", res.data.payment_token);
      return {
        message: "success",
        data: res.data,
      };
    } else {
      toast.error("Something went wrong, Please try again");
    }
  };
  const paymentVerification = async (ref) => {
    const res = await fetch(baseURL + `/order/verify-payment`, {
      method: "POST",
      body: JSON.stringify({
        payment_token: localStorage.payment_token,
        payment_ref: ref,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.esp_token}`,
      },
    });

    const response = await res.json();
    // console.log(response);

    if (response.status === 200) {
      localStorage.removeItem("payment_token");
      await cartService.fetchCart();
      return {
        message: "success",
        data: response.data,
      };
    } else {
      return {
        message: "failed",
      };
    }
  };
  const fetchOrders = async () => {
    const user = JSON.parse(localStorage.user);
    try {
      const res = await fetch(baseURL + `/order/get-all-orders-by-email`, {
        method: "POST",
        body: JSON.stringify({ email: `${user.email}` }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.esp_token}`,
        },
      });
      const response = await res.json();
      if (response.status === 200 || response.status === 203)
        return {
          message: "success",
          data: response.data,
        };
      else
        return {
          message: "failed",
        };
    } catch (err) {
      return {
        message: "failed",
        data: err,
      };
    }
  };
  const getOrderById = async (id) => {
    try {
      const response = await fetch(baseURL + `/order/get-order/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.esp_token}`,
        },
      });
      const res = await response.json();
      if (res.status === 200) {
        return {
          message: "success",
          data: res.data,
        };
      } else {
        return {
          message: " failed",
        };
      }
    } catch (err) {
      return {
        message: "failed",
        data: err,
      };
    }
  };
  return { initiatePayment, paymentVerification, fetchOrders, getOrderById };
};

export default useOrderService;

import React, { useState } from "react";
import Button from "../ui/Button";
import { userSevice } from "../MiddleWares/services/userService";
import { toast } from "react-hot-toast";
import { MdOutlineCelebration } from "react-icons/md";
import { AiFillCloseCircle } from "react-icons/ai";

const FgPassword = () => {
  const userService = new userSevice();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const handleChange = (e) => {
    setEmail(e.target.value);
    if (e.target.value === "") setError("Required*");
    else if (!validateEmail(e.target.value)) setError("Invalid email*");
    else setError("");
  };
  const validateForm = () => {
    const newError = {};
    if (email === "") newError.email = "Required*";
    else if (!validateEmail(email)) newError.email = "Invalid email*";
    return newError;
  };
  const handleSubmit = async () => {
    if (Object.keys(validateForm()).length > 0) {
      setError(validateForm().email);
    } else {
      setLoading(true);
      const res = await userService.forgotPassword(email);
      if (res.message === "success") {
        toast.success("Email has been sent");
        setSuccess(true);
        setEmail("");
      }
      setLoading(false);
    }
  };
  return (
    <div className=" min-h-[399px]">
      <div className="max-w-[1200px] m-auto w-full flex min-h-[500px] items-center justify-center">
        <form action="" className="max-w-[400px] w-full flex flex-col gap-5">
          {success && (
            <span className="flex justify-between   rounded-[20px] w-full bg-[#008000af] p-3 text-[#fff] text-[12px]">
              You will recieve an email on how to reset your password
              <span>
                <AiFillCloseCircle
                  onClick={() => setSuccess(false)}
                  className="text-[#fff] text-[16px] cursor-pointer"
                />
              </span>
            </span>
          )}
          <div className="flex flex-col ">
            <span className="text-[#fff] font-[700] sm:text-[36px] text-[32px]">
              Forgot Password
            </span>
            <span className="text-[0.8rem] text-gray-300">
              Enter your registered email address
            </span>
          </div>
          <div className="flex flex-col gap-3">
            <label className="text-[#fff] text-[0.8rem]" htmlFor="email">
              Email Address*
            </label>
            <input
              type="text"
              value={email}
              name="email"
              onChange={handleChange}
              className={`border ${
                error && error !== "" ? "border-[--red]" : " border-gray-300"
              } text-gray-600 h-[45px] rounded-[100px] px-5 p-1 outline-none`}
            />
            <span className="text-[0.8rem] text-[--red]">{error}</span>
          </div>
          <Button
            label={"Submit"}
            classes={"text-white h-[50px]"}
            loading={loading}
            handleSubmit={handleSubmit}
          />
        </form>
      </div>
    </div>
  );
};

export default FgPassword;

import React from "react";

const Input = ({ label, name, value, type, handleChange, error }) => {
  return (
    <div className="flex flex-col gap-3 w-full">
      <label className="text-[#fff] text-[0.8rem]" htmlFor={name}>
        {label}*
      </label>
      <input
        id={name}
        type={type}
        name={name}
        value={value}
        onChange={handleChange}
        className={`border ${
          error && error !== "" ? "border-[--red]" : " border-gray-300"
        } text-gray-600 h-[45px] rounded-[100px] px-5 p-1 outline-none`}
      />
      <span className="text-[0.8rem] text-[--red]">{error}</span>
    </div>
  );
};

const Input2 = ({ label, name, value, type, handleChange, error, classes }) => {
  return (
    <div className="flex flex-col gap-2">
      <span className="text-[12px] h-[0.6rem] text-[#fff] flex justify-between ">
        <span className="opacity-[0.6] font-[]">{value !== "" && label}</span>{" "}
        <span className="text-[0.7rem] opacity-1 text-[--red] h-[0.7rem]">
          {error}
        </span>
      </span>
      <input
        type={type}
        name={name}
        placeholder={label}
        className={
          "col-span-3 autofill:bg-transparent outline-0 text-[0.9rem] border-b font-[400] bg-transparent outline-none " +
          classes
        }
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};

export { Input, Input2 };

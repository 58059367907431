import React, { useState } from "react";
import Loader from "../../ui/Loader";
import Success from "../../ui/success";
import { Input2 } from "../../ui/Input";
import Button from "../../ui/Button";

const RecipientForm = ({ setRecipient, recipient }) => {
  const user = localStorage.user
    ? JSON.parse(localStorage.user)
    : {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        house_number: "",
        street: "",
        state: "",
      };
  const [form, setForm] = useState({
    first_name: user.first_name,
    last_name: user?.last_name,
    email: user?.email,
    phone: user?.phone,
    house_number: '',
    street:'',
    state: '',
  });
  
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    house_number: "",
    street: "",
    state: "",
  });
  const [loading, setLoading] = useState(false);

  const [hasInfo, setHasInfo] = useState(false);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const checkPhone = (value) => {
    const arr = ["", "1", "2", "3", "4", "5", "6", "7", "8", "9", "0", ""];
    if (arr.includes(value[value?.length - 1])) return true;
    else return false;
  };
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    // const phoneChecker = checkPhone(value?.toString());
    setForm({ ...form, [name]: value });

    if (value === "") {
      setErrors({ ...errors, [name]: "Required*" });
    } else if (name === "email" && !validateEmail(value)) {
      setErrors({ ...errors, [name]: "Email is not valid*" });
    } else if (name === "phone" && value.length !== 11) {
      setErrors({ ...errors, [name]: "Phone number incomplete*" });
    } else {
      setErrors({ ...errors, [name]: "" });
    }
  };
  const validateForm = () => {
    const newErrors = {};
    const { first_name, last_name, email, phone, house_number, street, state } =
      form;
    if (first_name === "" || first_name.length < 3)
      newErrors.first_name = "Required*";
    if (last_name === "" || last_name.length < 3)
      newErrors.last_name = "Required*";
    if (email === "") newErrors.email = "Required*";
    else if (!validateEmail(email))
      newErrors.email = "Enter a valid email address*";
    if (phone === "") newErrors.phone = "Required*";
    else if (phone.length !== 11)
      newErrors.phone = "Enter a valid phone number*";
    if (house_number === "") newErrors.house_number = "Required*";
    if (street === "") newErrors.street = "Required*";
    if (state === "") newErrors.state = "Required*";
    return newErrors;
  };
  const verifyRecipient = () => {
    const newErrors = {};
    const { first_name, last_name, email, phone } = form;
    if (first_name === "" || first_name.length < 3)
      newErrors.first_name = "Required*";
    if (last_name === "" || last_name.length < 3)
      newErrors.last_name = "Required*";
    if (email === "") newErrors.email = "Required*";
    else if (!validateEmail(email))
      newErrors.email = "Enter a valid email address*";
    if (phone === "") newErrors.phone = "Required*";
    else if (phone.length !== 11)
      newErrors.phone = "Enter a valid phone number*";

    if (Object.keys(newErrors).length > 0) {
      setErrors({ ...errors, ...newErrors });
    } else {
      setHasInfo(true);
    }
  };
  const handleSubmit = async () => {
    const formError = validateForm();
    if (Object.keys(formError).length > 0) {
      setErrors({ ...errors, ...formError });
    } else {
      setLoading(true);
      localStorage.setItem("recipient", JSON.stringify(form));
      setRecipient(true);
      setLoading(false);
    }
  };

  return !recipient ? (
    <form action="" className="relative flex flex-col gap-4 w-full ">
      <div className="flex flex-col">
        <span className="md:text-[36px] text-[32px] text-white ">
          Recipient Details
        </span>
        {/* <span className="text-[0.8rem] text-gray-300">
          Note that the information provided here will be used to process you
          order
        </span> */}
      </div>
      <div className="flex items-center text-[14px] gap-10 text-[#fff]">
        <span
          onClick={() => setHasInfo(false)}
          className={`${
            hasInfo ? "opacity-[0.4] " : "opacity-1 border-b "
          } cursor-pointer `}
        >
          Recipient information
        </span>
        <span
          className={`${
            !hasInfo ? "opacity-[0.4] " : "opacity-1 border-b "
          } cursor-pointer pb-1`}
        >
          Recipient address
        </span>
      </div>
      {!hasInfo ? (
        <div className="p-5 flex flex-col rounded-[20px] gap-5 ">
          <Input2
            label={"First name*"}
            name={"first_name"}
            value={form.first_name}
            type={"text"}
            handleChange={handleChange}
            error={errors.first_name}
            classes={"h-[35px] text-[#fff]"}
          />
          <Input2
            label={"Last name*"}
            name={"last_name"}
            value={form.last_name}
            type={"text"}
            handleChange={handleChange}
            error={errors.last_name}
            classes={"h-[35px] text-[#fff]"}
          />

          <Input2
            label={"Email*"}
            name={"email"}
            value={form.email}
            type={"email"}
            handleChange={handleChange}
            error={errors.email}
            classes={"h-[35px] text-[#fff]"}
          />
          <Input2
            label={"Phone*"}
            name={"phone"}
            value={form.phone}
            type={"number"}
            handleChange={handleChange}
            error={errors.phone}
            classes={"h-[35px] text-[#fff]"}
          />
          <Button
            classes={"h-[40px] text-[#fff]"}
            label={"Use as recipient"}
            loading={loading}
            handleSubmit={verifyRecipient}
          />
        </div>
      ) : (
        <div className="p-5 flex flex-col rounded-[20px] gap-5">
          <Input2
            label={"House number*"}
            name={"house_number"}
            value={form.house_number}
            type={"text"}
            handleChange={handleChange}
            error={errors.house_number}
            classes={"h-[35px] text-[#fff]"}
          />
          <Input2
            label={"Street*"}
            name={"street"}
            value={form.street}
            type={"text"}
            handleChange={handleChange}
            error={errors.street}
            classes={"h-[35px] text-[#fff]"}
          />
          <Input2
            label={"State*"}
            name={"state"}
            value={form.state}
            type={"text"}
            handleChange={handleChange}
            error={errors.state}
            classes={"h-[35px] text-[#fff]"}
          />
          <Button
            classes={"h-[40px] text-[#fff]"}
            label={"Use as recipient"}
            loading={loading}
            handleSubmit={handleSubmit}
          />
        </div>
      )}
    </form>
  ) : (
    <div className="flex flex-col w-full gap-10 items-center">
      <span className="text-[1.5rem] text-white font-[700] ">
        Recipient Saved
      </span>
      <Success />
      <span
        onClick={() => setRecipient(false)}
        className="bg-slate-500 hover:bg-slate-300 transition-all duration-150 text-teal-50 text-[0.8rem]  px-3 py-1 rounded-md cursor-pointer"
      >
        Edit Recipient info
      </span>
    </div>
  );
};

export default RecipientForm;

import { toast } from "react-hot-toast";
import { useAppContext } from "../context/appContext";

export class userSevice {
  baseURL = `https://esp-api.azurewebsites.net`;
  // baseURL = `https://vast-puce-cricket-vest.cyclic.app`;
  // baseURL = `http://localhost:2000`;
  state = useAppContext();

  updateUser = async (form) => {
    try {
      const res = await fetch(this.baseURL + `/user/update-user`, {
        method: "PATCH",
        body: JSON.stringify(form),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.esp_token}`,
        },
      });
      const response = await res.json();
      // console.log(response);
      if (response?.status === 200) {
        toast.success("Updated successfully");
        localStorage.setItem("user", JSON.stringify(response.data.user));
        return { message: "success" };
      } else {
        toast.error("Failed to update profile");
        return { message: "failed" };
      }
    } catch (err) {
      toast.error("Failed to update profile");
      return { message: "failed" };
    }
  };
  uploadImage = async (id, image) => {
    try {
      const res = await fetch(this.baseURL + `/user/upload/${id}`, {
        method: "PATCH",
        body: image,
        headers: {
          // 'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.esp_token}`,
        },
      });
      const response = await res.json();
      if (response?.status === 200) {
        toast.success("Updated successfully");
        localStorage.setItem("user", JSON.stringify(response.data.user));
        return { message: "success", data: response.data.image };
      } else {
        toast.error("Failed to update profile");
        return { message: "failed" };
      }
    } catch (err) {
      toast.error("Failed to update profile");
      return { message: "failed" };
    }
  };
  forgotPassword = async (email) => {
    try {
      const res = await fetch(this.baseURL + `/user/forgot-password`, {
        method: "POST",
        body: JSON.stringify({ email }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.esp_token}`,
        },
      });
      const response = await res.json();

      if (response.message === "success") {
        return { message: "success" };
      }
      if (response.message === "user not found") {
        toast.error("User not found");
        return { message: "failed" };
      } else {
        toast.error("Something went wrong");
        return { message: "failed" };
      }
    } catch (err) {
      toast.error("Something went wrong");
      return { message: "failed" };
    }
  };
  resetPassword = async (data) => {
    try {
      const res = await fetch(this.baseURL + `/user/reset-password`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.esp_token}`,
        },
      });
      const response = await res.json();
      if (response.message === "success") {
        return { message: "success" };
      } else if (response.message === "unauthorized") {
        toast.error("Unauthorized");
        return { message: "failed" };
      } else {
        toast.error("Something went wrong");
        return { message: "failed" };
      }
    } catch (err) {
      toast.error("Something went wrong");
      return { message: "failed" };
    }
  };
}

import React, { useState } from "react";
import { FaRegStar } from "react-icons/fa";
import "./Product.scss";
import { useNavigate } from "react-router-dom";
import { cartSevice } from "../../MiddleWares/services/cartService";
import { useAppContext } from "../../MiddleWares/context/appContext";
import Loader from "../../ui/Loader";
import { BsCartPlusFill } from "react-icons/bs";

const Product = ({ product }) => {
  const cartService = new cartSevice();
  const navigate = useNavigate();
  const appContext = useAppContext();

  const cart = appContext?.cart?.cartItems?.filter(
    (i) => i.product.id === product?._id
  );

  const [loading, setLoading] = useState(false);

  const toPrice = (amount) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    });
    return formatter.format(amount);
  };

  const addToCart = async () => {
    setLoading(true);
    if (localStorage.esp_token) {
      const res = await cartService.addToCart(product._id, 1);
      if (res.message === "success") await cartService.fetchCart();
    } else {
      // console.log("click");
      cartService.addToLocalCart(product, 1);
      cartService.fetchLocalCart();
    }
    setLoading(false);
  };

  // console.log(appContext?.cart?.cartItems);

  return (
    <div className="bg-[#ffffff94] rounded-[10px] overflow-hidden ease-in-out duration-200 relative flex flex-col ">
      <div
        onClick={() => navigate(`/product/${product._id}`)}
        style={{
          backgroundImage: `url(${product?.image})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
        className="w-full h-[300px] cursor-pointer"
      ></div>
      <div className="flex justify-end absolute top-3 left-3 px-3 ease-in-out duration-200">
        {product?.offer && (
          <span className="bg-[--red] text-[0.8rem] rounded-sm px-1 text-white">
            -{product?.offer}%
          </span>
        )}
      </div>
      <span
        onClick={() => (!cart || cart?.length < 1 ? addToCart() : null)}
        className={`${
          cart && cart?.length > 0 ? "opacity-[0.5]" : "opacity-[1]"
        } absolute shadow-lg cursor-pointer top-[20px] right-[20px] w-[45px] h-[45px] flex justify-center items-center rounded-full bg-[#fff]`}
      >
        {loading ? (
          <Loader color={"red"} size={[16, 16]} />
        ) : (
          <BsCartPlusFill className="text-[--red] text-[20px]" />
        )}
      </span>
      <div className="absolute bottom-0 right-0 w-full bg-[#2b292946]">
        <span
          onClick={() => navigate(`/product/${product._id}`)}
          className="h-[40px] cursor-pointer whitespace-nowrap truncate capitalize border-b text-[#fff] font-bold flex items-center flex-wrap px-3 text-[0.8rem] leading-5 overflow-hidden"
        >
          {product?.title}
        </span>
        <div className="flex flex-wrap-reverse px-3 justify-between items-center py-2">
          <span className="text-[#fff] text-[0.8rem]">
            {toPrice(product?.price)}
          </span>
          <div className="flex gap-1">
            <FaRegStar
              className={`${
                product?.rating > 0 ? "text-orange-500" : "text-gray-300"
              } text-[0.8rem]`}
            />
            <FaRegStar
              className={`${
                product?.rating > 1 ? "text-orange-500" : "text-gray-300"
              } text-[0.8rem]`}
            />
            <FaRegStar
              className={`${
                product?.rating > 2 ? "text-orange-500" : "text-gray-300"
              } text-[0.8rem]`}
            />
            <FaRegStar
              className={`${
                product?.rating > 3 ? "text-orange-500" : "text-gray-300"
              } text-[0.8rem]`}
            />
            <FaRegStar
              className={`${
                product?.rating > 4 ? "text-orange-500" : "text-gray-300"
              } text-[0.8rem]`}
            />
          </div>
        </div>
      </div>
      {/* <div className="w-full p-3">
        <span
          
          className=" w-full h-[45px] flex justify-center items-center transition-all cursor-pointer hover:bg-[--red] hover:opacity-[1] bg-[red] opacity-[0.5] ease-in-out duration-300 text-[1rem] font-[700] py-1 rounded-md text-white"
        >
          {loading ? (
            <Loader color={"#ffffff"} size={[15, 15]} />
          ) : cart && cart?.length > 0 ? (
            "In Cart"
          ) : (
            "Add To Cart"
          )}
        </span>
      </div> */}
    </div>
  );
};

export default Product;

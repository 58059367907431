import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { authSevice } from "../MiddleWares/services/authService";
import Loader from "../ui/Loader";

const VerifyAccount = () => {
  const route = useParams();
  const navigate = useNavigate();
  const authService = new authSevice();
  const [loading, setLoading] = useState(false);
  const verifyEmail = async () => {
    setLoading(true);
    const res = await authService.verifyEmail(route?.token);
    if (res?.message === "success") {
      navigate("/");
    }
    setLoading(false);
  };
  useEffect(() => {
    verifyEmail();
  }, []);
  return (
    <div className=" min-h-[399px]">
      <div className="max-w-[1200px] px-5 m-auto w-full flex min-h-[300px] items-center justify-center">
        <div
          action=""
          className="max-w-[500px] bg-[#80808034] min-h-[100px] p-5 gap-5 flex flex-col  w-full justify-center items-center"
        >
          <span className="text-gray-600 font-[600]">Verifying email</span>
          <Loader size={[50, 50]} color={"gray"} />
        </div>
      </div>
    </div>
  );
};

export default VerifyAccount;

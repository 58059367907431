import React from "react";
import { FaEllipsisH } from "react-icons/fa";
import Moment from "react-moment";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../ui/Button";
import { useAppContext } from "../../MiddleWares/context/appContext";

const OrderCard = ({ data }) => {
  // const route = useParams();
  const navigate = useNavigate();

  const { order, setOrder } = useAppContext();

  const toPrice = (amount) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    });
    return formatter.format(amount);
  };

  const getStatus = (status) => {
    if (status === "awaiting_pickup") return "bg-[goldenrod] text-[goldenrod]";
    else if (status === "awaiting_delivery")
      return "bg-[goldenrod] text-[goldenrod]";
    else if (status === "delivering") return "bg-[blue] text-[blue]";
    else if (status === "delivered") return "bg-[green] text-[green]";
    else if (status === "picked_up") return "bg-[green] text-[green]";
    else return "bg-[gray] text-[gray]";
  };

  return (
    <div
      className={`  ${
        order ? "md:col-span-6 " : "lg:col-span-4 col-span-6"
      }  flex  flex-col justify-between md:w-full md:min-w-fit w-full min-w-[300px] bg-[#000] dark_shadow rounded-[24px] h-[200px]  p-5`}
    >
      <div className="flex justify-between capitalize items-center w-full text-[13px] text-[#fff] font-[600]">
        <Moment fromNow>{data.createdAt}</Moment>
        <span className=" lowercase">
          {moment(data.createdAt).format("hh:mm a")}
        </span>
      </div>

      <div className="flex relative">
        {data?.order_details?.ordered_items?.slice(0, 3)?.map((item, n) => (
          <span
            key={n}
            className="w-[60px] h-[60px] overflow-hidden border red_shadow rounded-full "
          >
            <img src={item?.product?.image} alt="" />
          </span>
        ))}
        {data?.order_details?.ordered_items.length > 3 && (
          <span
            onClick={() => navigate(`/orders/order/${data?._id}`)}
            className="w-[60px] flex items-center gradient2 justify-center text-[#fff] primary_gradient text-[24px] h-[60px] border red_shadow rounded-full cursor-pointer"
          >
            <FaEllipsisH />
          </span>
        )}
      </div>
      <div className="flex items-center justify-between text-[12px] font-[700] text-[#fff] opacity-[0.6]">
        <span>{data?.order_details?.ordered_items.length} Items</span>
        <span>TOTAL : {toPrice(data?.order_details?.total)}</span>
      </div>
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-3">
          <span
            className={`h-[10px] w-[10px] ${getStatus(
              data?.pickup_details?.status
            )} rounded-full`}
          ></span>
          <span className="text-[12px] text-[#fff] font-[600] capitalize">
            {data?.pickup_details?.status?.replace("_", " ")}
          </span>
        </div>
        <Button
          handleSubmit={() => setOrder(data)}
          classes="h-[30px] text-[#fff] w-[80px] text-[#fff] font-[600] rounded-[24px] text-[13px] cursor-pointer"
          label={"View"}
        />
      </div>
    </div>
  );
};

export default OrderCard;

import React, { useState } from "react";
import { authSevice } from "../MiddleWares/services/authService";
import { Link, useNavigate } from "react-router-dom/dist";
import Loader from "../ui/Loader";

const Signup = () => {
  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    cf_password: "",
  });
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    cf_password: "",
  });
  const [loading, setLoading] = useState(false);
  // const [termError, setTermError] = useState(false);

  const navigate = useNavigate();
  const authService = new authSevice();

  const handleChange = (e) => {
    const { value, name } = e.target;
    setForm({ ...form, [name]: value });
    if (value === "") setErrors({ ...errors, [name]: "Required*" });
    else if (name === "email" && !validateEmail(value))
      setErrors({ ...errors, [name]: "Enter a valid email adddress" });
    else if (name === "password" && value.length < 8)
      setErrors({
        ...errors,
        [name]: "Password should at least 8 characters*",
      });
    else if (name === "cf_password" && value !== form.password)
      setErrors({
        ...errors,
        [name]: "Passwords do not match*",
      });
    else setErrors({ ...errors, [name]: "" });
  };
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const validateForm = () => {
    const { first_name, last_name, email, password, cf_password } = form;
    const error = {};
    if (first_name === "") error.first_name = "Required*";
    if (last_name === "") error.last_name = "Required*";
    if (email === "") error.email = "Required*";
    else if (email !== "" && !validateEmail(email))
      error.email = "Enter a valid email adddress";
    if (password === "") error.password = "Required*";
    else if (password.length < 8)
      error.password = "Passwords should be at least 8 characters";
    if (cf_password === "") error.cf_password = "Required*";
    else if (cf_password !== "" && cf_password !== password)
      error.cf_password = "Passwords do not match*";
    return error;
  };

  const handleSubmit = async () => {
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setLoading(true);
      const res = await authService.signup(form);
      if (res.message === "success") {
        // console.log(res);
        setLoading(false);
        localStorage.setItem("ver_email", form.email);
        navigate("/registration-success/");
      }
      setLoading(false);
    }
  };

  return (
    <div className=" min-h-[399px] pb-[60px]  pt-0">
      <div className="max-w-[1200px] m-auto w-full flex sm:min-h-[700px] min-h-[800px] items-center justify-center">
        <form action="" className="max-w-[450px]  w-full flex flex-col gap-5">
          <div className="flex flex-col ">
            <span className="text-[#fff] font-[700] sm:text-[48px] text-[32px]">
              Signup
            </span>
            <span className="text-[0.8rem] text-gray-300">
              Let's get you started
            </span>
          </div>

          <div className="flex flex-col gap-2  ">
            <div className="flex flex-col gap-3 w-full">
              <label className="text-[#fff] text-[0.8rem]" htmlFor="first-name">
                First Name*
              </label>
              <input
                id="first-name"
                type="text"
                name="first_name"
                value={form.first_name}
                onChange={handleChange}
                className={`border ${
                  errors.first_name && errors.first_name !== ""
                    ? "border-[--red]"
                    : " border-gray-300"
                } text-gray-600 h-[45px] rounded-[100px] px-5 p-1 outline-none`}
              />
              <span className="text-[0.8rem] text-[--red]">
                {errors.first_name}
              </span>
            </div>
            <div className="flex flex-col gap-3 w-full">
              <label className="text-[#fff] text-[0.8rem]" htmlFor="last-name">
                Last Name*
              </label>
              <input
                id="last-name"
                type="text"
                name="last_name"
                value={form.last_name}
                onChange={handleChange}
                className={`border ${
                  errors.last_name && errors.last_name !== ""
                    ? "border-[--red]"
                    : " border-gray-300"
                } text-gray-600 h-[45px] rounded-[100px] px-5 p-1 outline-none`}
              />
              <span className="text-[0.8rem] text-[--red]">
                {errors.last_name}
              </span>
            </div>
            <div className="flex flex-col gap-3 w-full">
              <label className="text-[#fff] text-[0.8rem]" htmlFor="email">
                Email*
              </label>
              <input
                id="email"
                type="text"
                name="email"
                value={form.email}
                onChange={handleChange}
                className={`border ${
                  errors.email && errors.email !== ""
                    ? "border-[--red]"
                    : " border-gray-300"
                } text-gray-600 h-[45px] rounded-[100px] px-5 p-1 outline-none`}
              />
              <span className="text-[0.8rem] text-[--red]">{errors.email}</span>
            </div>
            <div className="flex flex-col gap-3 w-full">
              <label className="text-[#fff] text-[0.8rem]" htmlFor="password">
                Password*
              </label>
              <input
                id="password"
                type="password"
                name="password"
                value={form.password}
                onChange={handleChange}
                className={`border ${
                  errors.password && errors.password !== ""
                    ? "border-[--red]"
                    : " border-gray-300"
                } text-gray-600 h-[45px] rounded-[100px] px-5 p-1 outline-none`}
              />
              <span className="text-[0.8rem] text-[--red]">
                {errors.password}
              </span>
            </div>
            <div className="flex flex-col gap-3 w-full">
              <label
                className="text-[#fff] text-[0.8rem]"
                htmlFor="cf_password"
              >
                Confirm Password*
              </label>
              <input
                id="cf_password"
                type="password"
                name="cf_password"
                value={form.cf_password}
                onChange={handleChange}
                className={`border ${
                  errors.cf_password && errors.cf_password !== ""
                    ? "border-[--red]"
                    : " border-gray-300"
                } text-gray-600 h-[45px] rounded-[100px] px-5 p-1 outline-none`}
              />
              <span className="text-[0.8rem] text-[--red]">
                {errors.cf_password}
              </span>
            </div>
          </div>

          <span
            onClick={handleSubmit}
            className="bg-[--red] flex cursor-pointer rounded-[100px] red_shadow justify-center items-center ease-in-out duration-300 text-white h-[50px]"
          >
            {loading ? <Loader color={"#fff"} size={[15, 15]} /> : "SUBMIT"}
          </span>

          <span className="text-[#fff] text-[0.9rem] cursor-pointer flex gap-3">
            Already have an account?
            <Link
              to={"/login"}
              className="text-[--red] cursor-pointer font-normal"
            >
              Login
            </Link>
          </span>
        </form>
      </div>
    </div>
  );
};

export default Signup;

import React from "react";
import { Link } from "react-router-dom";
import { BsFillBasketFill } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import logo from "../../../Assets/logo2.png";
import { Input2 } from "../../../ui/Input";
import Button from "../../../ui/Button";
import useProfile from "../../../Hooks/useProfile";
import { BiChevronDown } from "react-icons/bi";
import { AiFillCamera } from "react-icons/ai";
import Loader from "../../../ui/Loader";
import { useData } from "../../../Hooks/fetchData";

const Nav = () => {
  const {
    appContext,
    navigate,
    profile,
    openCart,
    openDelay,
    openDelay_,
    toggleRef,
    cartRef,
    profileRef,
    profileToggle,
    user,
    form,
    errors,
    loading,
    handleChange,
    handleSubmit,
    toPrice,
    toggleCart,
    toggleProfile,
    edit,
    setEdit,
    loadingImage,
    image,
    uploadImage,
    loadingDelete,
    deleteCartItem,
  } = useProfile();
  const { fetchCart } = useData();
  // console.log(appContext?.cart);
  return (
    <div className="flex w-full justify-between items-center ">
      <div className="flex items-center gap-20">
        <img
          onClick={() => navigate("/")}
          src={logo}
          className="sm:w-[150px] w-[100px] cursor-pointer"
          alt="logo"
        />
        <div className="md:flex hidden items-center gap-10 text-gray-50">
          <Link to="/products" className=" cursor-pointer">
            Products
          </Link>
          {localStorage.esp_token && (
            <Link to="/orders" className=" cursor-pointer">
              Orders
            </Link>
          )}
        </div>
      </div>
      <div className="flex items-center gap-5">
        <span
          onClick={toggleCart}
          ref={toggleRef}
          className="flex cursor-pointer relative gap-3 h-[50px] rounded-[20px] items-center md:p-3 p-1 px-3 text-red-600 "
        >
          <BsFillBasketFill className=" text-[30px] text-[#ff0019] red_shadow" />
          {appContext?.cart?.cartItems?.length > 0 && (
            <span className="absolute text-[0.5rem] text-[#FF0018] flex justify-center items-center top-0 shadow-xl right-0 bg-[#fff] border border-e-red-600 w-[16px] h-[16px] rounded-full">
              {appContext?.cart?.cartItems?.length}
            </span>
          )}
        </span>
        <div className="flex items-center gap-5">
          {localStorage.esp_token ? (
            <div
              onClick={toggleProfile}
              ref={profileToggle}
              className="flex  items-center gap-5"
            >
              <span className="w-[35px] cursor-pointer h-[35px] overflow-hidden flex items-center justify-center bg-[#ffffff2d] p-1 rounded-full">
                <img src={image} className="rounded-full" alt="" />
              </span>
              <div className="sm:flex hidden flex-col cursor-pointer max-w-[100px] overflow-hidden">
                <span className="text-[#fff] whitespace-nowrap truncate">
                  {user?.first_name} {user?.last_name[0]}
                </span>
                <span className="text-[#fff] truncate text-[12px] opacity-[0.4]">
                  {user?.email}
                </span>
              </div>
            </div>
          ) : (
            <>
              <Link
                to="/login"
                className="text-[16px] sm:bg-transparent flex items-center bg-[#ff0019] sm:w-fit w-[80px] justify-center h-[40px] rounded-[100px] text-gray-50"
              >
                Login
              </Link>
              <Link
                to={"/signup"}
                className="bg-[#ff0019] h-[40px] rounded-[100px] w-[100px] text-[16px] sm:flex hidden justify-center items-center text-white shadow-[0px,15px,30px,0px,rgba(255,0,24,0.16)]"
              >
                Sign Up
              </Link>
            </>
          )}
        </div>

        {openDelay && (
          <div
            ref={cartRef}
            className={`absolute bg-[#1d1d1d] rounded-[20px] z-[9999] shadow-lg p-5 w-[350px] h-fit transition-all duration-200 ease-in-out ${
              openCart
                ? "md:translate-x-[-20px] translate-x-0"
                : "translate-x-[650px]"
            } right-0 top-[120px] flex flex-col gap-3`}
          >
            {appContext?.cart?.cartItems?.length > 0 ? (
              <div className="flex flex-col gap-3">
                {appContext?.cart?.cartItems?.map((item, n) => {
                  return (
                    <div
                      key={n}
                      className="grid grid-cols-12 gap-5 items-center"
                    >
                      <img
                        className="w-[45px] h-[45px] rounded-[12px] col-span-3"
                        src={item.product.image}
                        alt=""
                      />
                      <div className="flex flex-col col-span-8">
                        <span className="text-[14px] text-[#fff] capitalize font-[500]">
                          {item?.product.title}
                        </span>
                        <span className=" whitespace-nowrap text-[#fff] opacity-[0.5] text-[0.6rem]">
                          {item?.quantity} X {toPrice(item.product.price)}
                        </span>
                      </div>
                      {loadingDelete ? (
                        <Loader color={"red"} size={[25, 25]} />
                      ) : (
                        <MdDelete
                          onClick={() =>
                            deleteCartItem(appContext?.cart?._id, item)
                          }
                          className="text-[#FF0018] cursor-pointer col-span-1"
                        />
                      )}
                    </div>
                  );
                })}
                <Button
                  label={"Proceed to checkout"}
                  handleSubmit={() => {
                    navigate("/checkout");
                    toggleCart();
                  }}
                  classes={"h-[35px] w-full text-[#fff] text-[12px]"}
                />
              </div>
            ) : (
              <div className=" h-[200px]  flex gap-5 flex-col justify-center items-center w-full">
                <span className="text-[27px] font-[700] leading-[36px] text-[#fff]">
                  Your cart is empty
                </span>
                <span className="w-[80px] h-[80px] bg-[#ffffff60] rounded-full flex justify-center items-center">
                  <BsFillBasketFill className=" text-[30px]" />
                </span>
              </div>
            )}
          </div>
        )}
        {openDelay_ && (
          <div
            ref={profileRef}
            className={`absolute bg-[#1d1d1d] rounded-[20px] z-[9999] shadow-lg p-5 w-[350px] h-fit transition-all duration-200 ease-in-out ${
              profile
                ? "md:translate-x-[-20px] translate-x-0"
                : "translate-x-[650px]"
            } right-0 top-[120px] flex flex-col gap-3`}
          >
            <div className="flex gap-4">
              <span className="w-[100px] h-[100px] flex items-center justify-center relative bg-[#ffffff3d] p-1 rounded-[20px] overflow-hidden">
                <img src={image} className="rounded-[20px]" alt="" />
                <div className="absolute bg-[#0000000a] top-0 bottom-0 w-full flex justify-center items-center">
                  {loadingImage ? (
                    <Loader size={[30, 30]} color={"red"} />
                  ) : (
                    <AiFillCamera className="text-[24px] text-[#000000ad]" />
                  )}
                </div>
                <input
                  type="file"
                  onChange={(e) => uploadImage(e.target.files[0])}
                  className="absolute z-[99999] opacity-0 cursor-pointer left-0 bottom-0 top-0 w-full"
                />
              </span>
              <div className="flex flex-col">
                <span className="text-[24px] whitespace-nowrap text-[#fff] truncate">
                  {user?.first_name} {user?.last_name}
                </span>
                <span className="text-[#fff] text-[12px] opacity-[0.5]">
                  {user?.email}
                </span>
                <span className="text-[#fff] text-[12px] opacity-[0.5]">
                  {user?.phone}
                </span>
                <span className="text-[#fff] text-[12px] opacity-[0.5]">
                  {user?.address?.house_number} {user?.address?.street}
                </span>
              </div>
            </div>
            <div className="grid grid-cols-12 gap-5">
              <Button
                label={
                  <span className="flex items-center justify-between whitespace-nowrap">
                    Edit Profile{" "}
                    <BiChevronDown
                      className={`transition-all duration-75 ${
                        edit ? "rotate-180" : "rotate-0"
                      }`}
                    />
                  </span>
                }
                bg={true}
                handleSubmit={() => setEdit(!edit)}
                classes={
                  "h-[35px] bg-[#000] col-span-4 text-[#fff] text-[12px]"
                }
              />
              <Button
                label={"Orders"}
                handleSubmit={() => {
                  toggleProfile();
                  fetchCart();
                  navigate("/orders");
                }}
                bg={true}
                classes={
                  "h-[35px] bg-[#474444] col-span-4 text-[#fff] text-[12px]"
                }
              />
              <Button
                label={"Logout"}
                handleSubmit={() => {
                  localStorage.clear();
                  toggleProfile();
                  navigate("/");
                }}
                classes={"h-[35px] col-span-4 text-[#fff] text-[12px]"}
              />
            </div>
            {edit && (
              <form className={`flex flex-col gap-3`} action="">
                <Input2
                  label={"Firt Name"}
                  name={"first_name"}
                  value={form.first_name}
                  handleChange={handleChange}
                  type={"text"}
                  classes={
                    "h-[30px] text-[#fff] border-b-[0.5px] border-b-[#ffffff27]"
                  }
                  error={errors.first_name}
                />
                <Input2
                  label={"last Name"}
                  name={"last_name"}
                  value={form.last_name}
                  handleChange={handleChange}
                  type={"text"}
                  classes={
                    "h-[30px] text-[#fff] border-b-[0.5px] border-b-[#ffffff27]"
                  }
                  error={errors.last_name}
                />
                <Input2
                  label={"Email"}
                  name={"email"}
                  value={form.email}
                  handleChange={handleChange}
                  type={"email"}
                  classes={
                    "h-[30px] text-[#fff] border-b-[0.5px] border-b-[#ffffff27]"
                  }
                  error={errors.email}
                />
                <Input2
                  label={"Phone Number"}
                  name={"phone"}
                  value={form.phone}
                  handleChange={handleChange}
                  type={"number"}
                  classes={
                    "h-[30px] text-[#fff] border-b-[0.5px] border-b-[#ffffff27]"
                  }
                  error={errors.phone}
                />
                <Input2
                  label={"House number"}
                  name={"house_number"}
                  value={form.house_number}
                  handleChange={handleChange}
                  type={"text"}
                  classes={
                    "h-[30px] text-[#fff] border-b-[0.5px] border-b-[#ffffff27]"
                  }
                  error={errors.house_number}
                />
                <Input2
                  label={"Street"}
                  name={"street"}
                  value={form.street}
                  handleChange={handleChange}
                  type={"text"}
                  classes={
                    "h-[30px] text-[#fff] border-b-[0.5px] border-b-[#ffffff27]"
                  }
                  error={errors.street}
                />
                <Button
                  loading={loading}
                  label={"Update profile"}
                  handleSubmit={handleSubmit}
                  classes={"h-[40px] text-[#fff]"}
                />
              </form>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Nav;

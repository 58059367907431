import React from "react";
import bg1 from "../../Assets/bg_15.jpg";

function HomeCard2() {
  return (
    <div className="w-full sm:h-[200px] h-[180px] rounded-xl relative border">
      <img className="w-full h-full rounded-xl" src={bg1} alt="" />
      <div className="absolute rounded-xl bg-[#00000080] sm:px-10 px-5 top-0 w-full h-full flex flex-col justify-center">
        <h2 className="lg:font-extrabold font-bold text-white sm:h-[5rem] lg:text-[2rem] sm:text-[1.7rem] text-[1.5rem] md:leading-10">We offer the best deals and services</h2>
        <p className=" text-white font-thin">Our services ranges between sales, consultation and installation.</p>
      </div>
    </div>
  );
}

export default HomeCard2;

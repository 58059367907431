import React from "react";
import Nav from "./utils/Nav";
import ContextBar from "./utils/ContextBar";
import { IoLocation } from "react-icons/io5";

const Header = () => {
  return (
    <div className="w-full flex sm:h-[150px] h-[120px] items-center gap-5 max-w-[1200px] m-auto">
      <Nav />
    </div>
  );
};

export default Header;
